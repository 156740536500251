import * as configcat from "configcat-js";
import { useEffect, useState } from "react";

import { CONFIGCAT_KEY } from "src/constants";
import { useCurrentUser } from "src/modules/User";

export enum Flags {
  SubmissionPeriodManagement = "showSubmissionPeriodManagement",
  StatePtdSettings = "stateptd",
  statePortalSidwisExport = "statePortalSidwisExport",
  statePortalSubmissionsExport = "statePortalSubmissionsExport",
}

const DEBUG = 2;
const OFF = -1;
const logLevel = process.env.NODE_ENV !== "development" ? OFF : DEBUG;
const logger = configcat.createConsoleLogger(logLevel);
const configCatClient = configcat.getClient(
  CONFIGCAT_KEY,
  configcat.PollingMode.AutoPoll,
  { logger }
);

const useUser = (): any => {
  const { user } = useCurrentUser();
  return {
    custom: { AccountId: user?.defaultAccount?.id.toString() },
    email: user?.email,
    identifier: user?.authId,
  };
};

const getValueForFlag = async (
  user: any,
  flag: string,
  defaultValue = false
): Promise<boolean> => {
  const value = await configCatClient.getValueAsync(flag, defaultValue, user);
  return !!value;
};

export const useConfig = (
  flag: string,
  defaultValue = false
): [boolean, boolean] => {
  const user = useUser();
  const [value, setValue] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getValueForFlag(user, flag, defaultValue)
      .then(setValue)
      .catch((e) => {})
      .finally(() => setLoaded(true));
  }, [user, flag, defaultValue]);

  return [value, loaded];
};

export const useConfigAsync = async (
  user: any,
  flag: string,
  defaultValue = false
): Promise<boolean> => {
  return await getValueForFlag(user, flag, defaultValue);
};
