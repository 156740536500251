import React, { SyntheticEvent, useState } from "react";
import {
  Button,
  LIGHTEST_GRAY,
  AQUA_BLUE,
  IGNIS_RED,
} from "@120wateraudit/waterworks";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";
import { toastError, toastSuccess } from "src/utils/toast";
import {
  useAssignSubmissionAssigneesMutation,
  useGetAccountUsersQuery,
  useGetCurrentPrimacyAgencyQuery,
  useUnassignSubmissionAssigneesMutation,
} from "src/services";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";

interface ChangeAssigneeModalProps {
  submissionIds: number[];
  closeModal: () => void;
}

const ChangeAssigneeModal = ({
  submissionIds,
  closeModal,
}: ChangeAssigneeModalProps): JSX.Element => {
  const [assignUsers] = useAssignSubmissionAssigneesMutation();
  const [unassignUsers] = useUnassignSubmissionAssigneesMutation();
  const { data: primacyAgency } = useGetCurrentPrimacyAgencyQuery(undefined);
  const { data: users } = useGetAccountUsersQuery({
    accountId: primacyAgency?.accountId ?? 0,
    permissions: ["stateportal:write"],
  });

  const [assignment, setAssignment] = useState<{
    isUnassigned: boolean;
    userId?: number;
  }>({ isUnassigned: false });

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (!submissionIds || submissionIds.length === 0) {
        return;
      }
      if (assignment.isUnassigned) {
        await unassignUsers(submissionIds).unwrap();
        toastSuccess("Users unassigned successfully");
        closeModal();
      } else if (assignment.userId) {
        await assignUsers({
          assignUsersDtos: submissionIds.map((submissionId) => {
            return {
              submissionId,
              userId: assignment.userId as number,
            };
          }),
        }).unwrap();

        toastSuccess("Users assigned successfully");
        closeModal();
      }
    } catch (error) {
      toastError(
        "Error assigning users. Please try again or contact support@120water.com"
      );
      closeModal();
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <ModalContentContainer>
      {assignment.isUnassigned
      ? (
        <h2 style={{ marginBottom: "3rem" }}>
          Unassign{" "}
          {submissionIds && submissionIds.length > 1
            ? `Submissions (${submissionIds.length})`
            : "Submission"}
        </h2>
      )
      : (
        <h2 style={{ marginBottom: "3rem" }}>
          Assign{" "}
          {submissionIds && submissionIds.length > 1
            ? `Submissions (${submissionIds.length})`
            : "Submission"}{" "}
          to a User
        </h2>
      )}
      <ContentContainer>
        <StyledDropdown
          options={(
            users?.items
              ?.map((user) => {
                return {
                  text: `${user.lastName}, ${user.firstName}`,
                  value: user.id.toString(),
                };
              })
              .sort((a, b) => a.text.localeCompare(b.text)) ?? []
          ).concat({
            text: "Unassigned",
            value: "null",
          })}
          onChange={(
            _event: SyntheticEvent<HTMLElement, Event>,
            data: { value: string }
          ) => {
            setAssignment((prev) => {
              return {
                ...prev,
                isUnassigned: data.value === "null",
                userId:
                  data.value === "null" ? undefined : parseInt(data.value),
              };
            });
          }}
          selection
          fluid
          placeholder="Select User"
        />

        <StlyedSubmitButton
          onClick={() => {
            void submit();
          }}
          isUnassign={assignment.isUnassigned}
        >
          {assignment.isUnassigned ? "Unassign" : "Assign User"}
        </StlyedSubmitButton>
        <StlyedCancelButton onClick={closeModal}>Cancel</StlyedCancelButton>
      </ContentContainer>
    </ModalContentContainer>
  );
};

const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: 1rem 0 3rem;
  align-items: center;
  justify-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 300px;
  width: 100%;
`;

const StyledDropdown = styled(Dropdown)`
  margin-bottom: 1rem;
  max-width: 300px;
`;

const StlyedSubmitButton = styled(Button)<{
  isUnassign?: boolean;
}>`
  background-color: ${({ isUnassign }) => (isUnassign ? IGNIS_RED : AQUA_BLUE)};
  color: white;
  width: 100%;
`;

const StlyedCancelButton = styled(Button)`
  background-color: ${LIGHTEST_GRAY};
`;

export default ChangeAssigneeModal;
