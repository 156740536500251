import { useMemo } from "react";

import { Values } from "src/modules/SubmissionPeriods/validation";

export const useInitialValues = (): Values => {
  return useMemo(() => {
    const values: Values = {
      name: "",
      submissionType: "",
      dueDate: "",
      lockedDate: "",
      overdueSetting: "",
      active: true,
    };

    return values;
  }, []);
};
