import {
  Column,
  FilterDefinition,
  FilterType,
  Table as WWTable,
  useMultiselection,
} from "@120wateraudit/waterworks";
import React, {
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { intersection } from "lodash";

import AddressCell from "src/components/Cells/AddressCell";
import LinkCell from "src/components/Cells/LinkCell";
import SubmissionPeriodCell from "src/components/Cells/SubmissionPeriodCell";
import {
  useGetPagedSystemsQuery,
  useGetAllSubmissionPeriodsQuery,
  useGetAvailableCountiesQuery,
  useGetAllTerritoriesQuery,
  useGetSavedViewsQuery,
  useDeleteSavedViewMutation,
} from "src/services";
import { Sort } from "src/types/Sort";
import System from "src/types/System";
import ActionMenu from "src/components/ActionMenu";
import { gotoSystem } from "src/utils/system";
import { TableContext } from "src/hooks/useTableState";
import { ModalContext } from "src/modules/Modal";
import { formatDate } from "src/utils/format";
import { isWrite } from "src/modules/User";
import { SAVED_VIEW_CATEGORY_MAP } from "src/components/SavedViews/constants";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { toastSuccess } from "src/utils/toast";
import { trueFalseNullListOptions } from "src/utils/selectList";
import { useGetTenantConfigQuery } from "src/services/ptd";

const TABLE_NAME = "utilities";

const ACTIONS = [
  {
    text: "Login to PWS Portal",
    onClick: gotoSystem,
    identifier: "accountId",
  },
];

interface TableProps {
  columns?: Array<Column<System>>;
  tableContext: TableContext;
}

const Table: React.FC<TableProps> = ({ tableContext }) => {
  const { closeModal, openModal } = useContext(ModalContext);
  const { data: counties, isLoading: countiesLoading } = useGetAvailableCountiesQuery();
  const { data: territories = [], isLoading: territoriesLoading } = useGetAllTerritoriesQuery({});
  const {
    params,
    setFilters,
    setActiveFilters,
    setPage,
    setPageSize,
    setSearchTerm,
    setSort,
  } = tableContext

  const {
    data: queryData,
    isFetching,
    isLoading,
    refetch: refetchSystems,
  } = useGetPagedSystemsQuery(params);

  const { data: periodData } = useGetAllSubmissionPeriodsQuery({
    filters: { active: true, locked: false },
    page: 1,
    pageSize: 100,
  });

  const { data: tenantSettings, isError: tenantSettingsError, isLoading: ptdSettingsLoading } =
      useGetTenantConfigQuery(null, { refetchOnMountOrArgChange: true });

  const submissionPeriods = periodData?.data ?? [];

  const submissionPeriodOptions: Array<{
    id: string;
    value: number;
    text: string;
  }> = submissionPeriods.map((sp) => {
    const { name, dueDate, id } = sp;
    return {
      id: name,
      value: id,
      text: `${name}, Due Date: ${formatDate(dueDate)}`,
    };
  });

  const COLUMNS = useMemo(() => {
    const columns = [
      {
        Header: "PWSID",
        name: "PWSID",
        key: "pwsId",
        sortable: true,
        accessor: ({ id, pwsId }: System) => (
          <LinkCell text={pwsId} to={`/utilities/${id}`} />
        ),
        hiddenOnColumnPicker: true,
      },
      {
        key: "name",
        sortable: true,
      },
      {
        Header: "PWS Type",
        name: "PWS Type",
        accessor: "pwsType",
        key: "pwsType",
        sortable: true,
      },
      {
        Header: "PWS Owner",
        name: "PWS Owner",
        accessor: "pwsOwner",
        key: "pwsOwner",
        sortable: true,
      },
      {
        Header: "Address",
        name: "Address",
        key: "address",
        sortable: false,
        accessor: ({ addressLine1, addressLine2, city, state }: System) => (
          <AddressCell
            addressLine1={addressLine1}
            addressLine2={addressLine2}
            city={city}
            state={state}
          />
        ),
      },
      {
        Header: "Population Served",
        name: "Population Served",
        accessor: "populationServed",
        key: "populationServed",
        sortable: true,
      },
      {
        Header: "Service Line Connections",
        name: "Service Line Connections",
        accessor: "serviceLineConnections",
        key: "serviceLineConnections",
        sortable: true,
      },
      {
        Header: "Active/Inactive",
        name: "Active/Inactive",
        accessor: ({ active }: System) => (active ? "Active" : "Inactive"),
        key: "active",
        sortable: true,
      },
      {
        Header: "Submission Period",
        name: "Submission Period",
        key: "submissionPeriod",
        sortable: false,
        accessor: (system: System) => <SubmissionPeriodCell system={system} />,
      },
      {
        Header: "Counties Served",
        name: "Counties Served",
        key: "counties",
        sortable: false,
        accessor: (system: System) => <p>{system.counties.map((c) => c.shortName).sort().join(', ')}</p>,
      },
      {
        Header: "Territory",
        name: "Territory",
        key: "territory.name",
        sortable: true,
        accessor: (system: System) => <p>{system?.territory?.name || "--"}</p>,
      },
      {
        Header: "Current PTD Opt-In Status",
        name: "Current PTD Opt-In Status",
        key: "ptdPwsSettings.consentToAutoPublish",
        sortable: true,
        accessor: (system: System) => (
            <p>{system?.ptdPwsSettings?.consentToAutoPublish === null ? '--' : system?.ptdPwsSettings?.consentToAutoPublish ? 'Yes' : 'No'}</p>
        )
      }
    ]

    if (!tenantSettingsError && tenantSettings?.publishOnAcceptance) {
      return columns.filter(c => c.key !== 'ptdPwsSettings.consentToAutoPublish')
    }
    return columns
  }, [tenantSettingsError, tenantSettings?.publishOnAcceptance]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        await refetchSystems();
      } catch (error) {
        console.error("Failed to refetch systems:", error);
      }
    };
    if (queryData) {
      void fetchData();
    }
  }, []);

  const { onClearSelection, onSelect, onSelectAll, selected, selectedIds } =
    useMultiselection<System>();

  const openChangeSubmissionPeriod = useCallback(
    () =>
      openModal("addSubmissionPeriod", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        selectedIds,
        warningCount: selected.length,
      }),
    [openModal, closeModal, selected]
  );

  const openRemoveSubmissionPeriod = useCallback(
    () =>
      openModal("removeSubmissionPeriod", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        selectedIds,
        warningCount: selected.length,
      }),
    [openModal, closeModal, selected]
  );

  const openChangeTerritory = useCallback(
    () =>
      openModal("changeTerritoryModal", {
        closeModal: async () => {
          onClearSelection();
          closeModal();
          await refetchSystems();
        },
        selectedIds,
        warningCount: selected.length,
      }),
    [openModal, closeModal, selected, refetchSystems]
  );

  const systems = queryData?.data ?? [];
  const total = queryData?.count ?? 0;

  const areAllSelected = useMemo(() => {
    const idsOnPage = systems.map((s) => s.id);
    return intersection(idsOnPage, selectedIds).length === idsOnPage.length;
  }, [systems, selectedIds]);

  const selectAllData = useCallback(() => {
    onSelectAll(systems, areAllSelected);
  }, [onSelectAll, systems, areAllSelected]);

  const { data: savedViews = [], isLoading: isSavedViewsLoading } = useGetSavedViewsQuery({ tableName: TABLE_NAME });

  const [deleteView] = useDeleteSavedViewMutation();

  const TABLE_ACTIONS = isWrite()
    ? [
        {
          label: "Assign Submission Period",
          onClick: openChangeSubmissionPeriod,
        },
        {
          label: "Remove Submission Period",
          onClick: openRemoveSubmissionPeriod,
        },
        {
          label: "Change Territory",
          onClick: openChangeTerritory,
        },
      ]
    : [];

  const ROW_ACTIONS_COLUMN = [
    {
      Header: "",
      hiddenOnColumnPicker: true,
      key: "id",
      sortable: false,
      accessor: (system: System) => (
        <ActionMenu
          identifiers={{ accountId: system.accountId.toString() }}
          actions={[
            ...ACTIONS,
            {
              text: "Show Activity",
              onClick: () =>
                openModal(
                  "systemTableActivityModal",
                  {
                    systemId: system.id,
                    modalSize: "large",
                    onClose: async () => {
                      closeModal();
                    },
                  },
                  "window"
                ),
              identifier: "systemId",
            },
          ]}
        />
      ),
    },
  ];

  const filters: FilterDefinition[] = useMemo(() => {
    const baseFilters = [
      {
        label: "Submission Period",
        key: "submissionPeriodId",
        type: FilterType.ListSelection,
        defaultValue: "All",
        multipleSelections: false,
        scrollingSelections: true,
        options: [
          ...submissionPeriodOptions,
          { id: "unassigned", value: 0, text: "Unassigned" },
        ],
      },
      {
        label: "Counties Served",
        key: "countiesServed",
        type: FilterType.ListSelection,
        multipleSelections: true,
        scrollingSelections: true,
        options: counties?.map((c) => {
          return {
            id: c.countyFIPS,
            value: c.countyFIPS,
            text: `${c.shortName} (${c.countyFIPS})`,
          };
        }),
      },
      {
        label: "Territory",
        key: "territory",
        type: FilterType.ListSelection,
        multipleSelections: true,
        scrollingSelections: true,
        options: [
          { id: "unassigned", value: 0, text: "Unassigned" },
          ...territories?.map((t) => {
            return {
              id: t.name,
              value: t.id,
              text: t.name,
            };
          }),
        ],
      },
      {
        label: "Current PTD Opt-In Status",
        key: "ptdOptIn",
        type: FilterType.ListSelection,
        multipleSelections: false,
        options: trueFalseNullListOptions,
      },
      {
        label: "Active/Inactive",
        key: "active",
        type: FilterType.ListSelection,
        multipleSelections: false,
        options: [
          { id: "active", value: "true", text: "Active" },
          { id: "inactive", value: "false", text: "Inactive" },
        ],
      }
    ]

    if (!tenantSettingsError && tenantSettings?.publishOnAcceptance) {
      return baseFilters.filter(bf => bf.key !== 'ptdOptIn')
    }
    return baseFilters
  }, [tenantSettingsError, tenantSettings?.publishOnAcceptance, territories, counties]);

  if (isSavedViewsLoading || ptdSettingsLoading || countiesLoading || territoriesLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <WWTable
      allowHiddenColumns={true}
      columns={[...COLUMNS]}
      rowActionColumn={ROW_ACTIONS_COLUMN}
      data={systems}
      onFilterChanged={setFilters}
      loading={isLoading || isFetching}
      onPageChanged={setPage}
      onPageSizeChanged={setPageSize}
      orderable={true}
      onSearchChanged={setSearchTerm}
      onSortChanged={setSort}
      page={params.page}
      activeFilters={params.filters}
      filterable
      filters={filters}
      pageSize={params.pageSize}
      paginated
      searchTerm={params.searchTerm}
      searchable
      sort={params.sort as Sort}
      sortable
      totalRecords={total}
      actions={TABLE_ACTIONS}
      areAllSelected={areAllSelected}
      selected={selected}
      selectionMode="multi"
      onClearSelection={onClearSelection}
      onSelect={onSelect}
      onSelectAll={selectAllData}
      showViews
      setActiveFilters={setActiveFilters}
      viewsList={savedViews.map((sv) => ({
        id: sv.id,
        category: SAVED_VIEW_CATEGORY_MAP[sv.ownerType],
        name: sv.name,
        ...JSON.parse(sv.view),
      }))}
      onSaveView={(old, newView, setViewId) => {
        if (!old) {
          openModal("createSavedViewModal", { view: newView, close: closeModal, tableName: TABLE_NAME, setViewId })
        } else {
          openModal("createOrEditSavedViewModal", { oldView: old, newView, close: closeModal, tableName: TABLE_NAME, setViewId })
        }
      }}
      allowViewEdit={isWrite()}
      onDeleteView={(view: { id: number, name: string }, remove) => {
        openModal('confirm', {
          title: 'Delete Saved View',
          body: `This action will permanently delete "${view.name}". This cannot be undone.`,
          confirmButtonText: `Yes, delete "${view.name}"`,
          onConfirm: async () => {
            await deleteView({ id: view.id });
            remove();
            toastSuccess(`Deleted "${view.name}"`);
            closeModal();
          }
        })
      }}
      maxHeight={"57vh"}
      stickyFirstColumn
      stickyHeader
    />
  );
};

export default Table;
