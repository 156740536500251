import { Badge, LIGHTEST_GRAY, IGNIS_RED } from "@120wateraudit/waterworks";
import React from "react";
import { Popup } from "semantic-ui-react";
import { SubmissionStatus } from "src/types/Submission";
import styled from "styled-components";

type Status = keyof typeof SubmissionStatus;

export const getColorFromStatus = (status: Status): string => {
  switch (status) {
    case "approved":
      return "#BBF7D0";
    case "inReview":
      return "#FDE68A";
    case "notSubmitted":
      return "#D3D3D3";
    case "rejected":
      return "#FECACA";
    case "submitted":
      return "#BFDBFE";
    case "processingSubmission":
      return "#00B5E2";
    case "voided":
      return "#ffc72c";
    case "failed":
      return IGNIS_RED;
    default:
      return LIGHTEST_GRAY;
  }
};

interface StatusBadgeProps {
  status: Status;
  style?: React.CSSProperties;
}

export const StatusBadge: React.FC<StatusBadgeProps> = ({ status, style }) => {
  const statusLabel = SubmissionStatus[status];

  if (status === "failed") {
    return (
      <StyledPopup
        trigger={(
          <div>
            <Badge
              style={{
                ...style,
                justifyContent: "center",
                textAlign: "center",
                minWidth: "120px",
              }}
              backgroundColor={getColorFromStatus(status)}
            >
              {statusLabel}
            </Badge>
          </div>
        )}
      >
        <PopupText>{'There was an error processing this submission. Utility must submit again.'}</PopupText>
      </StyledPopup>
    );
  }

  return (
    <Badge
      style={{
        ...style,
        justifyContent: "center",
        textAlign: "center",
        minWidth: "120px",
      }}
      backgroundColor={getColorFromStatus(status)}
    >
      {statusLabel}
    </Badge>
  )
};

export const StyledPopup = styled(Popup)`
  &&& {
    background-color: #192a3e;
    border-radius: 5px;
    :before {
      background-color: #192a3e;
    }
  }
`;

const PopupText = styled.div`
  background-color: #192a3e;
  color: #ffffff;
  white-space: pre-line;
`;
