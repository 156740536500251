import {
  Column,
  FilterDefinition,
  FilterType,
  Table as WWTable,
  useMultiselection,
  AQUA_BLUE,
} from "@120wateraudit/waterworks";
import React, { useCallback, useMemo, useContext, useEffect } from "react";
import { intersection } from "lodash";

import LinkCell from "src/components/Cells/LinkCell";
import { TableContext } from "src/hooks/useTableState";
import {
  PwsSubmissionPeriod,
  useGetPwsSubmissionPeriodsQuery,
} from "src/services/state-submission";
import Sort from "src/types/Sort";
import Submission, { SubmissionStatus } from "src/types/Submission";
import { formatDate } from "src/utils/format";
import { StatusBadge } from "./StatusBadge";
import ActionMenu from "src/components/ActionMenu";
import { gotoSystem } from "src/utils/system";
import { ModalContext } from "src/modules/Modal";
import { isWrite as useIsWrite } from "src/modules/User";
import {
  useDeleteSavedViewMutation,
  useGetAccountUsersQuery,
  useGetAllTerritoriesQuery,
  useGetAvailableCountiesQuery,
  useGetCurrentPrimacyAgencyQuery,
  useGetSavedViewsQuery,
} from "src/services";
import { Flags, useConfig } from "src/hooks/useConfig";
import {
  publishedUnpublishedListOptions,
  trueFalseListOptions,
  trueFalseNullListOptions,
} from "src/utils/selectList";
import System from "src/types/System";
import { useGetTenantConfigQuery } from "src/services/ptd";
import LoadingIndicatorPage from "src/components/LoadingIndicatorPage";
import { SAVED_VIEW_CATEGORY_MAP } from "src/components/SavedViews/constants";
import { toastSuccess } from "src/utils/toast";
import SubmissionCommunicationStatusBadge from "./SubmissionCommunicationStatusBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { SubmissionCommunication } from "src/types/SubmissionCommunication";
import styled from "styled-components";

type PwsSubmissionPeriodTableData = PwsSubmissionPeriod & { id: number };

const TABLE_NAME = "pws_submission_period";

interface TableProps {
  columns?: Array<Column<PwsSubmissionPeriod>>;
  submissionPeriodId: number;
  isOnUtilityDetailsPage?: boolean;
  tableContext: TableContext
}

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  &:hover {
    color: ${AQUA_BLUE};
    cursor: pointer;
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
  }
`;

const getAssigneeName = (submission: Submission | null): string => {
  if (!submission?.assigneeUserId) {
    return "--"
  }
  if (submission.assigneeFirstName !== undefined && submission.assigneeLastName !== undefined) {
    return `${submission.assigneeLastName}, ${submission.assigneeFirstName}`;
  } else if (submission.assigneeFirstName) {
    return submission.assigneeFirstName;
  } else if (submission.assigneeLastName) {
    return submission.assigneeLastName;
  }
  return `User ${submission.assigneeUserId}`;
};

export const PwsSubmissionPeriodsTable: React.FC<TableProps> = ({
  columns,
  submissionPeriodId,
  tableContext
}) => {
  const [statePtdEnabled, statePtdLoaded] = useConfig(Flags.StatePtdSettings);

  const {
    params,
    setFilters,
    setActiveFilters,
    setPage,
    setPageSize,
    setSearchTerm,
    setSort,
  } = tableContext

  const {
    data: pwsSubmissionPeriodQueryData,
    isFetching,
    refetch,
  } = useGetPwsSubmissionPeriodsQuery({
    submissionPeriodId,
    searchParams: params,
  });

  const { data: territories = [], isLoading: territoriesLoading } = useGetAllTerritoriesQuery({});

  const { onClearSelection, onSelect, onSelectAll, selected, selectedIds } =
    useMultiselection<PwsSubmissionPeriodTableData>();

  const { closeModal, openModal } = useContext(ModalContext);

  const { data: counties = [], isLoading: countiesLoading } = useGetAvailableCountiesQuery();
const { data: tenantSettings, isError: tenantSettingsError, isLoading: ptdSettingsLoading } =
      useGetTenantConfigQuery(null, { refetchOnMountOrArgChange: true });
  const isWrite = useIsWrite();

  const { data: primacyAgency } = useGetCurrentPrimacyAgencyQuery(undefined);
  const { data: accountUsers } = useGetAccountUsersQuery({
    accountId: primacyAgency?.accountId ?? 0,
    permissions: ["stateportal:write"],
  });

  useEffect(() => {
    if (submissionPeriodId) {
      onClearSelection();
    }
  }, [submissionPeriodId]);

  const openChangeSubmissionStatus = useCallback(
    () =>
      openModal("changeSubmissionStatusModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        selected,
      }),
    [openModal, closeModal, selected]
  );

  const changeAssignee = useCallback(
    () =>
      openModal("changeAssigneeModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        submissionIds: selected.map((s) => s.activeSubmissionId),
      }),
    [openModal, closeModal, selected]
  );

  const openPublishToPtd = useCallback(
    () =>
      openModal("publishToPtdModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        selected: selected.map((s) => ({
          ...s.activeSubmission,
          system: s.system,
        })),
      }),
    [openModal, closeModal, selected]
  );

  const openUnpublishFromPtd = useCallback(
    () =>
      openModal("unpublishPtdModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        selected: selected.map((s) => ({
          ...s.activeSubmission,
          system: s.system,
        })),
      }),
    [openModal, closeModal, selected]
  );

  const openChangeSingleSubmissionStatus = useCallback(
    (submissionId: number) =>
      openModal("changeSubmissionStatusModal", {
        closeModal: () => {
          onClearSelection();
          closeModal();
        },
        submissionId,
      }),
    [openModal, closeModal]
  );

  const openChangeSubmissionCommunicationStatus = useCallback(
    (
      value: string,
      pwsId: number,
      submissionPeriodId: number,
      pwsName: string,
      submissionCommunicationId: number,
      refetch: any
    ) =>
      openModal("changeConsumerNoticeModal", {
        closeModal: () => {
          closeModal();
        },
        value,
        pwsId,
        submissionPeriodId,
        pwsName,
        submissionCommunicationId,
        refetch,
      }),
    [openModal, closeModal]
  );

  const openViewCommunicationStatus = useCallback(
    (submissionCommunication: SubmissionCommunication, accountId: number) =>
      openModal("viewConsumerNoticeModal", {
        closeModal: () => {
          closeModal();
        },
        submissionCommunication,
        accountId,
      }),
    [openModal, closeModal]
  );

  // This is done this way because the WaterWorks table always expects an ID be present
  // TODO: Refactor WaterWorks table to allow for composite IDs
  const pwsSubmissionPeriods: PwsSubmissionPeriodTableData[] =
    pwsSubmissionPeriodQueryData?.data?.map(
      (psp: PwsSubmissionPeriod): PwsSubmissionPeriodTableData => ({
        id: Number(`${psp.pwsId}.${psp.submissionPeriodId}`),
        ...psp,
      })
    ) ?? [];
  const total = pwsSubmissionPeriodQueryData?.count ?? 0;

  const areAllSelected = useMemo(() => {
    const idsOnPage = pwsSubmissionPeriods.map((s) => s.id);
    return intersection(idsOnPage, selectedIds).length === idsOnPage.length;
  }, [pwsSubmissionPeriods, selectedIds]);

  const selectAllData = useCallback(() => {
    onSelectAll(pwsSubmissionPeriods, areAllSelected);
  }, [onSelectAll, pwsSubmissionPeriods, areAllSelected]);

  const TABLE_ACTIONS = useMemo(() => {
    const actions = isWrite
      ? [
          {
            label: "Change Submission Status",
            onClick: openChangeSubmissionStatus,
          },
          {
            label: "Change Assignee",
            onClick: changeAssignee,
          },
        ]
      : [];
    if (
      statePtdEnabled &&
      statePtdLoaded
    ) {
      if (!tenantSettingsError) {
          if (tenantSettings?.publishOnAcceptance) {
            actions.push({
              label: "Publish to PTD",
              onClick: openPublishToPtd,
            });
          }
          actions.push({
            label: "Unpublish from PTD",
            onClick: openUnpublishFromPtd,
          });
        }
    }
    return actions;
  }, [
    isWrite,
    openChangeSubmissionStatus,
    statePtdEnabled,
    statePtdLoaded,
    submissionPeriodId,
    openPublishToPtd,
    tenantSettingsError,
    tenantSettings?.publishOnAcceptance,
  ]);

  const { data: savedViews = [], isLoading: isSavedViewsLoading } =
    useGetSavedViewsQuery({ tableName: TABLE_NAME });

  const [deleteView] = useDeleteSavedViewMutation();

  const ROW_ACTIONS_COLUMN = [
    {
      Header: "",
      hiddenOnColumnPicker: true,
      key: "system.id",
      sortable: false,
      accessor: (psp: PwsSubmissionPeriodTableData) => {
        const primacyAgencyId = psp.submissionPeriod.primacyAgencyId;
        return (
          psp.activeSubmission && (
            <ActionMenu
              identifiers={{
                accountId: psp.system.accountId.toString(),
                submissionId: psp.activeSubmissionId.toString(),
              }}
              actions={getRowActions({ ...psp, primacyAgencyId })}
            />
          )
        );
      },
    },
  ];

  const getRowActions = useCallback(
    (submission: unknown): any => {
      const actions = [];
      actions.push({
        text: "Login to PWS Portal",
        onClick: gotoSystem,
        identifier: "accountId",
      });
      actions.push({
        text: "Show Activity",
        onClick: () =>
          openModal(
            "activityModal",
            {
              submission,
              modalSize: "large",
              onClose: async () => {
                closeModal();
              },
            },
            "window"
          ),
        identifier: "submissionId",
      });
      if (isWrite) {
        actions.push({
          text: "Change Status",
          onClick: openChangeSingleSubmissionStatus,
          identifier: "submissionId",
        });
        if (statePtdEnabled && statePtdLoaded) {
          if ((submission as any).activeSubmission.ptdPublished) {
            actions.push({
              text: "Unpublish from State PTD",
              onClick: () =>
                openModal("unpublishPtdModal", {
                  closeModal: () => {
                    onClearSelection();
                    closeModal();
                  },
                  selected: [
                    {
                      ...(submission as any).activeSubmission,
                      system: (submission as any).system,
                    },
                  ],
                }),
              identifier: "submissionId",
            });
          } else {
            actions.push({
              text: "Publish to State PTD",
              onClick: () =>
                openModal("publishToPtdModal", {
                  closeModal: () => {
                    onClearSelection();
                    closeModal();
                  },
                  selected: [
                    {
                      ...(submission as any).activeSubmission,
                      system: (submission as any).system,
                    },
                  ],
                }),
              identifier: "submissionId",
            });
          }
        }
      }
      return actions;
    },
    [isWrite, statePtdEnabled, statePtdLoaded]
  );

  const COLUMNS = useMemo(() => {
    const columns = [
      {
        Header: "PWS",
        key: "system.name",
        name: "PWS",
        sortable: true,
        accessor: ({
          system: { name, pwsId },
          activeSubmissionId,
        }: PwsSubmissionPeriod) => (
          <div>
            {/* eslint-disable-next-line multiline-ternary */}
            {activeSubmissionId ? (
              <LinkCell text={name} to={`/submissions/${activeSubmissionId}`} />
            ) : (
              <p>{name}</p>
            )}
            <p>{pwsId}</p>
          </div>
        ),
        hiddenOnColumnPicker: true,
      },
      {
        Header: "Submitted By",
        name: "Submitted By",
        accessor: "activeSubmission.submittedByName",
        key: "activeSubmission.submittedByName",
        sortable: true,
      },
      {
        Header: "Submitted Date",
        name: "Submitted Date",
        key: "activeSubmission.submittedOn",
        sortable: true,
        accessor: (psp: PwsSubmissionPeriodTableData) =>
          psp.activeSubmission
            ? formatDate(psp.activeSubmission.submittedOn)
            : "--",
      },
      {
        Header: "PWS Type",
        name: "PWS Type",
        key: "system.pwsType",
        sortable: true,
      },
      {
        Header: "Service Line Connections",
        name: "Service Line Connections",
        key: "system.serviceLineConnections",
        sortable: true,
      },
      {
        Header: "Percent Known",
        name: "Percent Known",
        accessor: ({ activeSubmission }: PwsSubmissionPeriod) =>
          activeSubmission ? <>{activeSubmission.percentKnown}% Known</> : <></>,
        key: "activeSubmission.percentKnown",
        sortable: true,
      },
      {
        Header: "Total Lines",
        name: "Total Lines",
        accessor: "activeSubmission.totalServiceLinesSubmitted",
        key: "activeSubmission.totalServiceLinesSubmitted",
        sortable: true,
      },
      {
        Header: "Lead",
        name: "Lead",
        accessor: "activeSubmission.totalServiceLinesLead",
        key: "activeSubmission.totalServiceLinesLead",
        sortable: true,
      },
      {
        Header: "GRR",
        name: "GRR",
        accessor: "activeSubmission.totalServiceLinesGalvanized",
        key: "activeSubmission.totalServiceLinesGalvanized",
        sortable: true,
      },
      {
        Header: "Unknown",
        name: "Unknown",
        accessor: "activeSubmission.totalServiceLinesUnknown",
        key: "activeSubmission.totalServiceLinesUnknown",
        sortable: true,
      },
      {
        Header: "Non-lead",
        name: "Non-lead",
        accessor: "activeSubmission.totalServiceLinesNonLead",
        key: "activeSubmission.totalServiceLinesNonLead",
        sortable: true,
      },
      {
        Header: "Assigned To",
        name: "Assigned To",
        key: "activeSubmission.assigneeLastName",
        sortable: true,
        accessor: (psp: PwsSubmissionPeriod) => {
          return (
            <p>
              {getAssigneeName(psp.activeSubmission)}
            </p>
          );
        },
      },
      {
        Header: "Submission Status",
        name: "Submission Status",
        key: "status",
        sortable: true,
        accessor: (psp: PwsSubmissionPeriodTableData) => (
          <StatusBadge status={psp.status} />
        ),
      },
      {
        Header: "Overdue",
        name: "Overdue",
        key: "overdue",
        sortable: true,
        accessor: (psp: PwsSubmissionPeriodTableData) =>
          psp.overdue ? "Yes" : "No",
      },
      {
        Header: "Submission Attempts",
        name: "Submission Attempts",
        key: "submissionAttemptCount",
        sortable: true,
      },
      {
        Header: "Counties Served",
        name: "Counties Served",
        key: "counties",
        sortable: false,
        accessor: (psp: PwsSubmissionPeriod) => (
          <p>
            {psp.system.counties
              .map((c) => c.shortName)
              .sort()
              .join(", ")}
          </p>
        ),
      },
      {
        Header: "Territory",
        name: "Territory",
        key: "system.territory.name",
        sortable: true,
        accessor: ({ system }: { system: System }) => (
          <p>{system?.territory?.name || "--"}</p>
        ),
      },
      {
        Header: "PTD Published Status",
        name: "Published Status",
        key: "activeSubmission.ptdPublished",
        sortable: true,
        accessor: ({ activeSubmission }: { activeSubmission: Submission }) => (
          <p>{activeSubmission?.ptdPublished ? "Published" : "Unpublished"}</p>
        ),
      },
      {
        Header: "Current PTD Opt-In Status",
        name: "Current PTD Opt-In Status",
        key: "system.ptdPwsSettings.consentToAutoPublish",
        sortable: true,
        accessor: ({ system }: { system: System }) => (
          <p>
            {system?.ptdPwsSettings?.consentToAutoPublish === null
              ? "--"
              : system?.ptdPwsSettings?.consentToAutoPublish
              ? "Yes"
              : "No"}
          </p>
        ),
      },
      {
        Header: "Customer Notice",
        name: "Customer Notice",
        key: "submissionCommunication.status",
        sortable: true,
        accessor: (
          pwsSubmissionPeriodTableData: PwsSubmissionPeriodTableData
        ) => (
          <SubmissionCommunicationStatusBadge
            openChangeSubmissionCommunicationStatus={
              openChangeSubmissionCommunicationStatus
            }
            pwsSubmissionPeriodTableData={pwsSubmissionPeriodTableData}
            refetch={refetch}
          />
        ),
      },
      {
        Header: "Certification Completion Date",
        name: "Certification Completion Date",
        key: "submissionCommunication.completedDate",
        sortable: true,
        accessor: (
          pwsSubmissionPeriodTableData: PwsSubmissionPeriodTableData
        ) => (
          <p>
            {formatDate(
              pwsSubmissionPeriodTableData?.submissionCommunication
                ?.completedDate
            ) || "--"}
          </p>
        ),
      },
      {
        Header: "Certificate Responses",
        name: "Certificate Responses",
        key: "certificateResponses",
        sortable: false,
        accessor: (
          pwsSubmissionPeriodTableData: PwsSubmissionPeriodTableData
        ) =>
          pwsSubmissionPeriodTableData?.submissionCommunication
? (
            <StyledFontAwesomeIcon
              onClick={() =>
                openViewCommunicationStatus(
                  pwsSubmissionPeriodTableData.submissionCommunication,
                  pwsSubmissionPeriodTableData.system.accountId
                )
              }
              icon={faFile}
            />
          )
: (
            "--"
          ),
      },
    ];

    if (!tenantSettingsError && tenantSettings?.publishOnAcceptance) {
      return columns.filter(c => c.key !== "system.ptdPwsSettings.consentToAutoPublish")
    }

    return columns;
  },
  [
    statePtdEnabled,
    statePtdLoaded,
    tenantSettingsError,
    tenantSettings?.publishOnAcceptance
  ]);

  const filters: FilterDefinition[] = useMemo(() => {
    const baseFilters = [
      {
        key: "serviceLineConnections",
        label: "Service Line Connections",
        type: FilterType.Number,
      },
      {
        label: "Status",
        key: "status",
        type: FilterType.ListSelection,
        defaultValue: "All",
        multipleSelections: false,
        scrollingSelections: true,
        options: Object.entries(SubmissionStatus).map(([key, val]) => ({
          id: key,
          value: key,
          text: val,
        })),
      },
      {
        label: "Total Lines",
        key: "totalServiceLinesSubmitted",
        type: FilterType.Number,
      },
      {
        label: "Percent Known",
        key: "percentKnown",
        type: FilterType.Number,
      },
      {
        label: "Overdue",
        key: "overdue",
        type: FilterType.ListSelection,
        defaultValue: "All",
        multipleSelections: false,
        options: trueFalseListOptions,
      },
      {
        label: "PTD Published Status",
        key: "ptdPublished",
        type: FilterType.ListSelection,
        multipleSelections: false,
        options: publishedUnpublishedListOptions,
      },
      {
        label: "Current PTD Opt-In Status",
        key: "ptdOptIn",
        type: FilterType.ListSelection,
        multipleSelections: false,
        options: trueFalseNullListOptions,
      },
      {
        label: "Counties Served",
        key: "countiesServed",
        type: FilterType.ListSelection,
        multipleSelections: true,
        scrollingSelections: true,
        options: counties?.map((c) => ({
          id: c.countyFIPS,
          value: c.countyFIPS,
          text: `${c.shortName} (${c.countyFIPS})`,
        })),
      },
      {
        label: "Lead",
        key: "totalLead",
        type: FilterType.Number,
      },
      {
        label: "GRR",
        key: "totalGRR",
        type: FilterType.Number,
      },
      {
        label: "Unknown",
        key: "totalUnknown",
        type: FilterType.Number,
      },
      {
        label: "Non-Lead",
        key: "totalNonLead",
        type: FilterType.Number,
      },
      {
        label: "Submitted By",
        key: "submittedBy",
        type: FilterType.TextMatch,
      },
      {
        label: "PWS Type",
        key: "pwsType",
        type: FilterType.ListSelection,
        options: [
          {
            id: "CWS",
            value: "CWS",
            text: "CWS",
          },
          {
            id: "NTNCWS",
            value: "NTNCWS",
            text: "NTNCWS",
          },
        ],
      },
      {
        label: "Submission Attempts",
        key: "submissionAttempts",
        type: FilterType.Number,
      },
      {
        label: "Territory",
        key: "territory",
        type: FilterType.ListSelection,
        multipleSelections: true,
        scrollingSelections: true,
        options: [
          { id: "unassigned", value: 0, text: "Unassigned" },
          ...territories?.map((t) => {
            return {
              id: t.name,
              value: t.id,
              text: t.name,
            };
          }),
        ],
      },
      {
        label: "Customer Notice",
        key: "customerNotice",
        type: FilterType.ListSelection,
        options: [
          {
            id: "Submitted",
            value: "Submitted",
            text: "Submitted",
          },
          {
            id: "Accepted",
            value: "Accepted",
            text: "Accepted",
          },
          {
            id: "Rejected",
            value: "Rejected",
            text: "Rejected",
          },
          {
            id: "Not Submitted",
            value: "Not Submitted",
            text: "Not Submitted",
          },
        ],
      },
      {
        label: "Certificate Completion Date",
        key: "certificateCompletionDate",
        type: FilterType.DatePicker,
      },
      {
        label: "Submitted Date",
        key: "submittedOn",
        type: FilterType.DatePicker,
      },
      {
        label: "Assigned To",
        key: "assigneeUserId",
        type: FilterType.ListSelection,
        options: [
          {
            id: "unassigned",
            value: "null" as any,
            text: "Unassigned",
          },
        ].concat(
          accountUsers?.items?.map((u) => ({
            id: `user-${u.id}`,
            value: u.id,
            text: `${u.lastName}, ${u.firstName}`,
          })) ?? []
        ),
      },
    ].sort((a, b) => a.label.localeCompare(b.label));

    if (!tenantSettingsError && tenantSettings?.publishOnAcceptance) {
      return baseFilters.filter(bf => bf.key !== 'ptdOptIn')
    }

    return baseFilters
  }, [tenantSettingsError, tenantSettings?.publishOnAcceptance, territories, counties, accountUsers]);

  if (isSavedViewsLoading || ptdSettingsLoading || territoriesLoading || countiesLoading) {
    return <LoadingIndicatorPage />;
  }

  return (
    <>
      <style>
      {`.ui.dropdown > .menu {
        max-height: 300px ;
        overflow-y: auto ;
      }`}
      </style>
      <WWTable
        activeFilters={params.filters}
        allowHiddenColumns={true}
        columns={columns ?? [...COLUMNS]}
        rowActionColumn={ROW_ACTIONS_COLUMN}
        data={pwsSubmissionPeriods}
        filterable
        filters={filters}
        itemName="Submissions"
        loading={isFetching}
        onFilterChanged={setFilters}
        onPageChanged={setPage}
        onPageSizeChanged={setPageSize}
        orderable={true}
        onSearchChanged={setSearchTerm}
        onSortChanged={setSort}
        page={params.page}
        pageSize={params.pageSize}
        paginated
        searchTerm={params.searchTerm}
        searchable
        sort={params.sort as Sort}
        sortable
        totalRecords={total}
        areAllSelected={areAllSelected}
        actions={TABLE_ACTIONS}
        selected={selected}
        selectionMode="multi"
        onClearSelection={onClearSelection}
        onSelect={onSelect}
        onSelectAll={selectAllData}
        showViews
        setActiveFilters={setActiveFilters}
        viewsList={savedViews.map((sv) => ({
          id: sv.id,
          category: SAVED_VIEW_CATEGORY_MAP[sv.ownerType],
          name: sv.name,
          ...JSON.parse(sv.view),
        }))}
        onSaveView={(old, newView, setViewId) => {
          if (!old) {
            openModal("createSavedViewModal", {
              view: newView,
              close: closeModal,
              tableName: TABLE_NAME,
              setViewId,
            });
          } else {
            openModal("createOrEditSavedViewModal", {
              oldView: old,
              newView,
              close: closeModal,
              tableName: TABLE_NAME,
              setViewId,
            });
          }
        }}
        allowViewEdit={isWrite}
        onDeleteView={(view: { id: number; name: string }, remove) => {
          openModal("confirm", {
            title: "Delete Saved View",
            body: `This action will permanently delete "${view.name}". This cannot be undone.`,
            confirmButtonText: `Yes, delete "${view.name}"`,
            onConfirm: async () => {
              await deleteView({ id: view.id });
              remove();
              toastSuccess(`Deleted "${view.name}"`);
              closeModal();
            },
          });
        }}
        maxHeight={"57vh"}
        stickyFirstColumn
        stickyHeader
      />
    </>
  );
};

export default PwsSubmissionPeriodsTable;
