import noop from "lodash/noop";
import React, {
  ComponentClass,
  FC,
  FunctionComponent,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Modal, ModalHeader, ModalProps, Sidebar, SidebarProps } from "semantic-ui-react";
import styled from "styled-components";

import Drawer from "./Drawer";
import ModalNotFound from "./NotFound";
import { MODALS } from "./modals";

type ModalFormat = "drawer" | "window";

type ModalSize = ModalProps["size"] | SidebarProps["width"];

type ModalTarget = Record<number | string | symbol, unknown> & {
  modalSize?: ModalSize;
  headerText?: string;
};

type ModalName = keyof typeof MODALS;

interface ModalState {
  format?: ModalFormat;
  name?: ModalName;
  target?: null | ModalTarget;
}

interface ModalTriggers {
  closeModal: () => void;
  openModal: (
    name: ModalName,
    target?: ModalTarget,
    format?: ModalFormat,
    onClose?: () => void
  ) => void;
}

const getModalSize = (
  format: ModalFormat = "window",
  size: ModalSize = "small"
): string => {
  if (
    format === "window" &&
    ["mini", "tiny", "small", "large", "fullscreen"].includes(size)
  ) {
    return size;
  } else if (
    format === "drawer" &&
    ["very thin", "thin", "wide", "very wide"].includes(size)
  ) {
    return size;
  } else if (format === "drawer") {
    return "wide";
  }

  return "small";
};

export const ModalContext = createContext<ModalTriggers>({
  closeModal: noop,
  openModal: noop,
});

interface Props {
  children: React.ReactNode;
}

const ModalSystem: FC<Props> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const closeDrawer = useCallback(() => setDrawerOpen(false), [setDrawerOpen]);
  const [modalState, setModalState] = useState<ModalState>({
    format: undefined,
    name: undefined,
    target: undefined,
  });
  const { format, name, target } = modalState;
  const openModal = useCallback(
    (
      name: ModalName,
      target?: null | ModalTarget,
      format: ModalFormat = "window"
    ) => {
      setModalState({ format, name, target });
    },
    [setModalState]
  );
  const closeModal = useCallback(() => {
    setModalState({ format: undefined, name: undefined, target: undefined });
  }, [setModalState, name]);
  const modalComponent: ComponentClass<any> | FunctionComponent<any> = name
    ? MODALS[name] || ModalNotFound
    : ModalNotFound;
  const isSystemModal = name === "confirm" || modalComponent === ModalNotFound;
  const modalSize = isSystemModal
    ? format === "window"
      ? "tiny"
      : "wide"
    : getModalSize(format, target?.modalSize);
  const visibleDrawer = !!name && format === "drawer";
  const visibleWindow = !!name && format === "window";
  useEffect(() => setDrawerOpen(visibleDrawer), [visibleDrawer]);
  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Sidebar.Pushable>
        <Drawer onClose={closeModal} visible={drawerOpen} width={modalSize}>
          {React.createElement(modalComponent, {
            ...target,
            onClose: closeDrawer,
          })}
        </Drawer>
        <Pusher dimmed={drawerOpen}>
          <StateDashboardModal
            centered={false}
            onClose={closeModal}
            open={visibleWindow}
            size={modalSize as ModalProps["size"]}
          >
            <ModalHeader>
              <p>{ target?.headerText }</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16" onClick={closeModal}>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
            </ModalHeader>
            {React.createElement(modalComponent, {
              ...target,
              onClose: closeModal,
            })}
          </StateDashboardModal>
          {children}
        </Pusher>
      </Sidebar.Pushable>
    </ModalContext.Provider>
  );
};

const Pusher = styled(Sidebar.Pusher)`
  height: 100vh;
  overflow-y: scroll !important;
  &.pusher.dimmed:after {
    position: fixed;
  }
` as typeof Sidebar.Pusher;

const StateDashboardModal = styled(Modal)`
  &&& {
    border-radius: 8px;

    .header {
      display: flex;
      align-items: center;
      border-bottom: none;

      p {
        flex: 1 1 auto;
        font-size: 14px;
        text-align: center;
        margin: 0;
        transform: translateX(16px);
      }

      svg {
        cursor: pointer;
        z-index: 100;
      }
    }
  }
`

export default ModalSystem;
