import { Card } from "@120wateraudit/waterworks";
import React, { CSSProperties } from "react";
import { Popup } from "semantic-ui-react";
import styled from "styled-components";
import {
  useGetGroupedStatusCountsQuery,
} from "src/services/state-submission";
import { aggregateStatusCounts } from "src/utils/aggregateStatusCounts";
import { calculateMetricWidth } from "src/utils/calculateMetricWidth";

interface StatusProps {
  backgroundColor: string;
  popupText: React.ReactNode;
  width: string;
}

const Status = ({
  backgroundColor,
  popupText,
  width,
}: StatusProps): null | JSX.Element => {
  return (
    <StyledPopup
      trigger={
        <StatusContent width={width} backgroundColor={backgroundColor} />
      }
    >
      <PopupText>{popupText}</PopupText>
    </StyledPopup>
  );
};

interface Props {
  className?: string;
  style?: CSSProperties;
  title?: string;
  submissionPeriodId?: number;
}

const SubmissionsStatusesMetricsCard = ({
  submissionPeriodId
}: Props): null | JSX.Element => {
  const { data: groupedStatuses } = useGetGroupedStatusCountsQuery(
    { submissionPeriodId: submissionPeriodId ?? 0 }
  );
  const statusCounts = aggregateStatusCounts(groupedStatuses?.data)
  const total = statusCounts.statusesTotal
  const inReviewPercent = calculateMetricWidth(statusCounts.aggregateInReview, total);
  const acceptedPercent = calculateMetricWidth(statusCounts.aggregateAccepted, total);
  const outstandingPercent = calculateMetricWidth(statusCounts.aggregateOutstanding, total);
  const rejectedPercent = calculateMetricWidth(statusCounts.aggregateRejected, total);

  return (
    <Card
      style={{ marginBottom: "12px", width: "min-content", maxWidth: "100%" }}
    >
      <StatusesBreakdownContainer>
        <StatusesTitleSection>
          <h2>Submission Breakdown</h2>
          <CountBadge>
            <h4>
              {total} Utilities
            </h4>
          </CountBadge>
        </StatusesTitleSection>
        <StatusesContainer>
          <StatusesBar>
            {(statusCounts.aggregateAccepted > 0) && (
              <Status
                backgroundColor="#BBF7D0"
                popupText={`Accepted: ${statusCounts.aggregateAccepted}`}
                width={acceptedPercent}
              />
            )}
            {(statusCounts.aggregateInReview > 0) && (
              <Status
                backgroundColor="#BFDBFE"
                popupText={`Submitted: ${statusCounts.submitted} \n In Review: ${statusCounts.inReview} \n Processing Submission: ${statusCounts.processingSubmission}`}
                width={inReviewPercent}
              />
            )}
            {(statusCounts.aggregateOutstanding > 0) && (
              <Status
                backgroundColor="#D3D3D3"
                popupText={`Not Submitted: ${statusCounts.notSubmitted}`}
                width={outstandingPercent}
              />
            )}
            {(statusCounts.aggregateRejected > 0) && (
              <Status
                backgroundColor="#FECACA"
                popupText={`Rejected: ${statusCounts.rejected} \n Voided: ${statusCounts.voided} \n Failed: ${statusCounts.failed}`}
                width={rejectedPercent}
              />
            )}
          </StatusesBar>
          <Legend>
            <LegendContent>
              <StatusBadge backgroundColor="#BBF7D0">
                {statusCounts.aggregateAccepted}
              </StatusBadge>
              <LegendText>Accepted</LegendText>
            </LegendContent>
            <LegendContent>
              <StatusBadge backgroundColor="#BFDBFE">
                {statusCounts.aggregateInReview}
              </StatusBadge>
              <LegendText>In Review</LegendText>
            </LegendContent>
            <LegendContent>
              <StatusBadge backgroundColor="#D3D3D3">
                {statusCounts.aggregateOutstanding}
              </StatusBadge>
              <LegendText>Not Submitted</LegendText>
            </LegendContent>
            <LegendContent>
              <StatusBadge backgroundColor="#FECACA">
                {statusCounts.aggregateRejected}
              </StatusBadge>
              <LegendText>Rejected</LegendText>
            </LegendContent>
          </Legend>
        </StatusesContainer>
      </StatusesBreakdownContainer>
    </Card>
  );
};

const StatusesBreakdownContainer = styled.div`
    display: flex;
`;

const StatusesTitleSection = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: center;
  margin-right: 1rem;
  width: 25rem;
`;

const StatusesContainer = styled.div`
  display: flex;
  flex: 7;
  flex-direction: column;
  justify-content: center;
`;

const CountBadge = styled.div`
  background: #02B5E180;
  border-radius: 50vh;
  width: fit-content;
  margin: .2rem;
  padding: .3rem .8rem;
`;

const Legend = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 15px;
  @media (max-width: 1160px) {
    gap: 1rem;
  }
  @media (max-width: 615px) {
    flex-wrap: wrap;
  }
`;

const LegendText = styled.span`
  margin-left: 5px;
  font-size: body;
  font-weight: 700;
`;

const StatusesBar = styled.div`
  display: flex;
  min-height: 70px;
`;

const StatusContent = styled.div<{ backgroundColor: string; width: string }>`
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  border-radius: 10px;
  margin: 0.25rem;
  cursor: pointer;
`;

const LegendContent = styled.div`
  display: flex;
  align-items: center;
`;

const StatusBadge = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  text-align: center;
  color: #333333;
  font-weight: 700;
  border-radius: 10px;
  margin: 0.25rem;
  padding: 0.25rem 1rem;
  min-width: 50px;
`;

const PopupText = styled.div`
  background-color: #192a3e;
  color: #ffffff;
  white-space: pre-line;
`;

export const StyledPopup = styled(Popup)`
  &&& {
    background-color: #192a3e;
    border-radius: 5px;
    :before {
      background-color: #192a3e;
    }
  }
`;
export default SubmissionsStatusesMetricsCard;
