import System from "./System";
import { Submission } from "./Submission";

export interface SubmissionPeriod extends Record<string, unknown> {
  id: number;
  locked: boolean;
  lockedDate: string;
  dueDate: string;
  active: boolean;
  sandbox?: boolean;
  name: string;
  period: string;
  primacyAgencyId: number;
  serviceLineConnections: number;
  startDate: string;
  submissions: Submission[];
  systems: System[];
  modifiedOn: string;
  modifiedBy: string;
  createdOn: string;
  createdBy: string;
  submissionType: string;
  overdueSetting: string;
}

export const PERIOD_NAME_REGEX = /^(?:[qQ]\d ?- ?)?\d{4}$/;

export default SubmissionPeriod;
