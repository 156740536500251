/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/query/react";

import { User } from "src/modules/User/types";
import baseQuery from "./baseQuery";
import { Tag, TagLabel } from "./tags";
import { AccountManagementCounty } from "src/types/AccountManagementCounty";
import { Account } from "src/types/Account";
import { PaginatedResponse } from "./utils";

export const accountManagementService = createApi({
  baseQuery: baseQuery({ baseUrl: "/platform/account-management/rest" }),
  reducerPath: "accntmgmt",
  tagTypes: [Tag.Users, Tag.Counties, Tag.Accounts],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      providesTags: [{ id: TagLabel.Current, type: Tag.Users }],
      query: () => `/currentuser/@me`,
    }),
    getAvailableCounties: builder.query<AccountManagementCounty[], void>({
      providesTags: [{ id: TagLabel.Current, type: Tag.Counties }],
      query: () => "/currentuser/defaultaccount/available-counties",
    }),
    getAccountUsers: builder.query<
      PaginatedResponse<User>,
      { accountId: number; permissions?: string[] }
    >({
      query: ({ accountId, permissions }) =>
        `/accounts/${accountId}/users?pageSize=10000${
          permissions ? `&permissions=${permissions.join(",")}` : ""
        }`,
    }),
    getAccountById: builder.query<Account, number>({
      providesTags: [{ id: TagLabel.Current, type: Tag.Accounts }],
      query: (id) => `/accounts/${id}`,
    }),
  }),
});

export const { useLazyGetCurrentUserQuery, useGetAvailableCountiesQuery, useGetAccountUsersQuery, useGetAccountByIdQuery } = accountManagementService;

export default accountManagementService;
