import React from "react";
import styled from "styled-components";
import SubmissionPeriod from "src/types/SubmissionPeriod";

import Loader from "src/components/Loader";
import { Button, IGNIS_RED, TERRA_GREEN } from "@120wateraudit/waterworks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "semantic-ui-react";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "src/utils/format";
import { useGetAllSubmissionPeriodsQuery } from "src/services";
import { useToggle } from "src/hooks/useToggle";
import CreateSubmissionPeriod from "./CreateSubmissionPeriodForm/CreateSubmissionPeriod";
import EditSubmissionPeriod from "./EditSubmissionPeriodForm/EditSubmissionPeriod";

const ManageSubmissionPeriods: React.FC = (): JSX.Element => {
  const [isEditMode, toggleEditMode] = useToggle();
  const [isCreateMode, toggleCreateMode] = useToggle();

  const { data: queryData, isFetching } = useGetAllSubmissionPeriodsQuery({
    filters: {},
    page: 1,
    pageSize: 10000,
  });

  const submissionPeriods = queryData?.data ?? [];

  const submissionPeriod = {};

  if (isCreateMode) {
    return (
      <CreateSubmissionPeriod
        refetch={undefined}
        toggleCreateMode={toggleCreateMode}
      />
    );
  }

  if (isEditMode) {
    return (
      <EditSubmissionPeriod
        refetch={undefined}
        submissionPeriod={submissionPeriod}
        toggleEditMode={toggleEditMode}
      />
    );
  }

  return (
    <Wrapper>
      {/* eslint-disable-next-line multiline-ternary */}
      {isFetching ? (
        <LoaderWrapper>
          <Loader size="3x" />
        </LoaderWrapper>
      ) : (
        <div>
          <TopWrapper>
            <Header>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 style={{ fontSize: "24px", fontWeight: "600" }}>
                  Manage Submission Periods
                </h3>
                <AddNewButton onClick={toggleCreateMode}>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "22px",
                      marginRight: "5px",
                    }}
                  >
                    +
                  </span>{" "}
                  Create New Submission Period
                </AddNewButton>
              </div>
              <p style={{ fontSize: "15px", fontWeight: "400" }}>
                Submission periods organize and manage incoming submissions from
                your water systems
              </p>
            </Header>
          </TopWrapper>
          <SubmissionPeriodsContainer>
            {submissionPeriods.map(
              ({
                active,
                id,
                name,
                dueDate,
                submissionType,
              }: SubmissionPeriod) => (
                <SubmissionPeriodRow key={id}>
                  <Title
                    style={{
                      width: "230px",
                      fontWeight: "400",
                    }}
                  >
                    {name || "--"}
                  </Title>
                  <Title
                    style={{
                      width: "135px",
                    }}
                  >
                    Type:{" "}
                    <Text
                      style={{
                        textTransform: "capitalize",
                        width: "135px",
                      }}
                    >
                      {submissionType || "--"}
                    </Text>
                  </Title>
                  <Title
                    style={{
                      marginRight: "5px",
                    }}
                  >
                    Due Date: <Text>{formatDate(dueDate) || "--"}</Text>
                  </Title>
                  <Title
                    style={{
                      width: "210px",
                      display: "flex",
                      alignItems: "center",
                      gap: "1.5em",
                    }}
                  >
                    Status:
                    <Checkbox checked={active} toggle />
                    <Text
                      style={{
                        color: active ? TERRA_GREEN : IGNIS_RED,
                        fontSize: "15px",
                      }}
                    >
                      {active ? "Open" : "Closed"}
                    </Text>
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1em",
                    }}
                  >
                    <StyledFontAwesomeIcon
                      onClick={toggleEditMode}
                      icon={faPenToSquare}
                      size={"lg"}
                    />
                    <StyledFontAwesomeIcon
                      icon={faTrashCan}
                      size={"lg"}
                      color={IGNIS_RED}
                    />
                  </div>
                </SubmissionPeriodRow>
              )
            )}
          </SubmissionPeriodsContainer>
        </div>
      )}
    </Wrapper>
  );
};

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 300;
`;

const Text = styled.span`
  font-weight: 400;
`;

const Wrapper = styled.div`
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const SubmissionPeriodsContainer = styled.div`
  display: grid;
  gap: 20px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
`;

const SubmissionPeriodRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AddNewButton = styled(Button)`
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
`;

export default ManageSubmissionPeriods;
