/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from "@reduxjs/toolkit/query/react";

import baseQuery from "./baseQuery";
import { Tag, TagLabel } from "./tags";
import Submission from "src/types/Submission";

export interface SubmissionPeriodMetricsParams {
  submissionPeriodId: number;
}

export interface TenantConfig {
  name?: string;
  active: boolean;
  title?: string;
  pageUrl?: string;
  logoBase64?: string;
  disclaimer?: string;
  infoPanel?: string;
  systemOwnedMaterial?: string;
  customerOwnedMaterial?: string;
  allowUtilityLinks: boolean;
  publishOnAcceptance: boolean;
  autoPublish: boolean;
  tenantType: {
    tenantType: string;
    disclaimer?: string;
  };
  mapCode?: string;
  inventoryDownloadEnabled: boolean;
  inventoryDownloadType: string;
  showEpaClassification: boolean;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
}

export interface Submissions {
  submissions: number[];
}

export interface PublishSubmissionsResponse {
  publishedItems: Array<{
    submission: Submission;
    message?: string;
  }>;
  invalidItems: Array<{
    submission: Submission;
    message?: string;
  }>;
}

export const ptdApi = createApi({
  baseQuery: baseQuery({ baseUrl: "/pws/state-submission" }),
  reducerPath: "ptdApi",
  tagTypes: [Tag.Ptd, Tag.Submissions, Tag.SubmissionPeriods],
  endpoints: (builder) => ({
    getTenantConfig: builder.query<TenantConfig, null>({
      providesTags: [{ id: TagLabel.Current, type: Tag.Ptd }],
      query: () => {
        return {
          method: "GET",
          url: `/admin/ptd/pageSettings`,
        };
      },
      transformResponse(baseQueryReturnValue: TenantConfig | null) {
        if (!baseQueryReturnValue) {
          return {
            active: false,
            allowUtilityLinks: false,
            autoPublish: false,
            createdOn: "",
            createdBy: "",
            modifiedBy: "",
            modifiedOn: "",
            publishOnAcceptance: false,
            inventoryDownloadEnabled: false,
            inventoryDownloadType: "approved",
            tenantType: {
              tenantType: "primacyAgency",
            },
            showEpaClassification: false,
          };
        }
        return baseQueryReturnValue;
      },
    }),
    putTenantConfig: builder.mutation<TenantConfig, TenantConfig>({
      invalidatesTags: [{ id: TagLabel.Current, type: Tag.Ptd }],
      query: (body) => {
        return {
          method: "PUT",
          url: `/admin/ptd/pageSettings`,
          body,
        };
      },
    }),
    postSyncTenantData: builder.mutation<null, null>({
      invalidatesTags: [{ id: TagLabel.Current, type: Tag.Ptd }],
      query: () => {
        return {
          method: "POST",
          url: `/admin/ptd/syncTenantData`,
        };
      },
    }),
    postPublishSubmissions: builder.mutation<
      PublishSubmissionsResponse,
      Submissions
    >({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.Submissions },
        { id: TagLabel.Current, type: Tag.Ptd },
        { id: TagLabel.List, type: Tag.SubmissionPeriods },
      ],
      query: (submissions) => {
        return {
          method: "POST",
          url: `/ptd/publishPwsSubmissions`,
          body: submissions,
        };
      },
    }),
    postUnpublishSubmissions: builder.mutation<PublishSubmissionsResponse, Submissions>({
      invalidatesTags: [
        { id: TagLabel.List, type: Tag.Submissions },
        { id: TagLabel.Current, type: Tag.Ptd },
        { id: TagLabel.List, type: Tag.SubmissionPeriods },
      ],
      query: (submissions) => {
        return {
          method: "POST",
          url: `/ptd/unpublishPwsSubmissions`,
          body: submissions,
        };
      },
    }),
  }),
});

export const {
  useGetTenantConfigQuery,
  usePutTenantConfigMutation,
  usePostPublishSubmissionsMutation,
  usePostUnpublishSubmissionsMutation,
  usePostSyncTenantDataMutation
} = ptdApi;

export default ptdApi;
