// TODO fix
/* eslint-disable @typescript-eslint/no-misused-promises */
import { Button } from "semantic-ui-react";
import { IGNIS_RED, PRIMARY, TERRA_GREEN } from "@120wateraudit/waterworks";
import React, { useState } from "react";
import Submission, { SubmissionStatus } from "src/types/Submission";
import { titleCase } from "src/utils/format";
import styled from "styled-components";

interface Props {
    submission: Submission;
    status: keyof typeof SubmissionStatus;
    onClose: () => void;
    onConfirm: () => Promise<unknown>;
}

const RejectVoidAcceptedSubmissionModal = ({
    submission,
    status,
    onClose,
    onConfirm,
}: Props): JSX.Element => {
    const [loading, setLoading] = useState(false);
    const confirm: () => Promise<void> = async () => {
        setLoading(true);
        if (await onConfirm()) {
        onClose();
        }
        setLoading(false);
    };

    return (
        <StyledModalContent>
            <h1>Are you sure you want to <span style={{ color: IGNIS_RED }}>{titleCase(status === 'rejected' ? 'reject' : 'void')}</span> the <span style={{ color: TERRA_GREEN }}>{titleCase(submission.status)}</span> status of this submission?</h1>
            <p>Moving a submission out of Acceptance will exclude its data from the Insights Dashboard as well as any following SDWIS Exports.</p>
            <Button variant="primary" style={{ backgroundColor: PRIMARY, color: 'white', height: "40px", width: "198px", marginBottom: "16px" }} loading={loading} onClick={confirm}>{titleCase(status === 'rejected' ? 'reject' : 'void')} Submission</Button>
            <Button style={{ height: "40px" }} onClick={onClose}>Cancel</Button>
        </StyledModalContent>
    )
}

const StyledModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 20px 125px;

    p, h1 {
        text-align: center;
    }

    p {
        font-size: 20px;
        line-height: 24px;
        color: #192A3E;
    }

    h1 {
        font-size: 24px;
        line-height: 28.8px;
    }
`;

export default RejectVoidAcceptedSubmissionModal;
