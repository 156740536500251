import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import ManageTerritories from "src/modules/Territories/ManageTerritories";
import {
  DisplaySettingsFlagged as DisplaySettings,
  PublishSettingsFlagged as PublishSettings,
} from "src/modules/Ptd";
import SubmissionPeriodSettings from "src/modules/SubmissionPeriods/ManageSubmissionPeriods";
import ExportLog from "src/modules/Exports/ExportLog";
import { Flags, useConfig } from "src/hooks/useConfig";

const AdminSections: FC = () => {
  const [submissionPeriodManagementEnabled] = useConfig(
    Flags.SubmissionPeriodManagement
  );
  return (
    <Wrapper>
      <Routes>
        <Route path="manageTerritories" element={<ManageTerritories />} />
        <Route path="statePtdDisplay" element={<DisplaySettings />} />
        <Route path="statePtdPublishing" element={<PublishSettings />} />
        {submissionPeriodManagementEnabled && (
          <Route
            path="manageSubmissionPeriods"
            element={<SubmissionPeriodSettings />}
          />
        )}
        <Route path="exportLog" element={<ExportLog />} />

        <Route path="/" element={<ManageTerritories />} />
      </Routes>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 25px;
`;

export default AdminSections;
