import { StateMap } from "@120wateraudit/primacy-agency-county-map-component";
import { Card, DARK_BLUE, LIGHTEST_GRAY } from "@120wateraudit/waterworks";
import React, { useCallback, useEffect, useState } from "react";
import { Icon, Dropdown } from "semantic-ui-react";
import { MaterialCircle } from "src/components/Icons/MaterialCircle";
import { MapDetail } from "./MapDetail";
import { MaterialType, COLOR_MAP, LABEL_MAP } from "./dashboardConstants";
import styled from "styled-components";
import Column from "src/components/Column";
import {
  CountiesMetric,
  PwsSummary,
  useLazyGetPwsSummariesByCountyQuery,
  useGetUnmappedPwsSummariesQuery,
} from "src/services";
import { useTooltip } from "src/hooks/useTooltip";
import { calculateOpaqueColor } from "src/utils/color";
import Loader from "src/components/Loader";
import Error from "src/components/Error";
import { toastError } from "src/utils/toast";
import { Link } from "react-router-dom";
import { SubmissionStatuses } from "src/types/Submission";

const MapContainer = styled.div`
  width: 50%;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MapDetailsContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: lightgray;
  > div:first-child {
    border-radius: 5px 5px 0 0;
  }
  > div:last-child {
    border-radius: 0 0 5px 5px;
  }
`;

const Meter = styled.div<{ color: string; startColor?: string }>`
  width: 100%;
  height: 14px;
  border: 1px solid lightgray;
  border-radius: 10px;
  background: ${({ color, startColor }) =>
    `linear-gradient(90deg, ${startColor ?? "fff"} 0%, ${color} 100%)`};
`;

const UtilityInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px 0;
`;
const MaterialTypesTitleContainer = styled.div<{
  color: string;
}>`
  border: 1px solid ${({ color }) => color};
  border-radius: 5px;
  padding: 5px 15px;
  background-color: ${({ color }) => color}33;
`;

function padZeros(num: number): string {
  let str = String(num);
  while (str.length < 5) {
    str = "0" + str;
  }
  return str;
}

function getCountyMakeupPercentages(
  county: CountiesMetric[string]
): Record<MaterialType, number> {
  if (!county) {
    return {
      lead: 0,
      grr: 0,
      nonlead: 0,
      unknown: 0,
    };
  }
  return county.total > 0
    ? {
        lead: county.lead / county.total,
        grr: county.grr / county.total,
        nonlead: county.nonlead / county.total,
        unknown: county.unknown / county.total,
      }
    : {
        lead: 0,
        grr: 0,
        nonlead: 0,
        unknown: 0,
      };
}

function getSubmissionPeriodStatusBadgeColor(
  status: SubmissionStatuses
): string {
  console.warn(status);
  if (
    status === "inReview" ||
    status === "processingSubmission" ||
    status === "submitted"
  ) {
    return "#BFDAFE";
  }
  if (status === "notSubmitted") {
    return "#D5D3D5";
  }
  if (status === "voided" || status === "rejected" || status === "failed") {
    return "#FFC8CD";
  }
  if (status === "approved") {
    return "#BCF6D7";
  }

  return "#fff";
}

const UtilityDetails: React.FC<{
  utility: PwsSummary;
  style?: React.CSSProperties;
}> = ({ utility, style }) => {
  const data = [
    {
      label: "PWS ID",
      value: utility.pwsId,
    },
    {
      label: "Submission Period",
      value: (
        <div style={{ display: "inline-flex", alignItems: "center", gap: 5 }}>
          <span>{utility.submissionPeriodName ?? "N/A"}</span>{" "}
          {utility.status && <MaterialCircle
            color={getSubmissionPeriodStatusBadgeColor(utility.status)}
          />}
        </div>
      ),
    },
    {
      label: "Total Service Lines",
      value: utility.totalServiceLines ?? "N/A",
    },
    {
      label: "System Type",
      value: utility.systemType ?? "N/A",
    },
    {
      label: "Counties Served",
      value: utility.countiesServed ?? "N/A",
    },
  ];
  return (
    <div
      style={{
        fontSize: "0.8em",
        ...style,
      }}
    >
      <h4
        style={{
          marginBottom: 5,
        }}
      >
        <Link
          to={`/utilities/${utility.id}`}
          style={{
            textDecoration: "underline",
          }}
        >
          {utility.pwsName}
        </Link>
      </h4>
      {data.map((item) => (
        <p
          key={item.label}
          style={{
            marginBottom: 2,
          }}
        >
          <strong>{item.label}:</strong> {item.value}
        </p>
      ))}
    </div>
  );
};

export const MapSection: React.FC<{
  state: string;
  countyMakeUpData?: CountiesMetric;
  shadingOffset?: number;
}> = ({ state, countyMakeUpData, shadingOffset = 0.2 }) => {
  const [viewMapBy, setViewMapBy] = useState<MaterialType>(MaterialType.LEAD);
  const [selectedCounty, setSelectedCounty] = useState<number>(0);
  const [countyTooltip, setCountyTooltip] = useState<string | null>(null);
  const [viewbox, setViewbox] = useState<[number, number, number, number]>([
    0, 0, 0, 0,
  ]);
  const { setTooltipContent, setTooltipPosition } = useTooltip();

  const [triggerPwsSummariesByCounty, resultPwsSummariesByCounty] =
    useLazyGetPwsSummariesByCountyQuery();
  const {
    data: unmappedPwsSummariesData,
    isLoading: isUnmappedPwsSummariesLoading,
    isError: isUnmappedPwsSummariesError,
  } = useGetUnmappedPwsSummariesQuery(null);

  useEffect(() => {
    const clickOutside = (e: MouseEvent): void => {
      if (
        e.target instanceof Element &&
        !e.target.id.startsWith("county-") &&
        e.target.closest("svg") !== null
      ) {
        setSelectedCounty(0);
        setCountyTooltip(null);
      }
    };

    document.addEventListener("click", clickOutside);
    return () => document.removeEventListener("click", clickOutside);
  }, []);

  useEffect(() => {
    if (!selectedCounty) {
      document.querySelectorAll("path[selected]").forEach((el) => {
        el.removeAttribute("selected");
      });
      return;
    }
    const county = document.getElementById(
      `county-${padZeros(selectedCounty)}`
    );
    document.querySelectorAll("path[selected]").forEach((el) => {
      el.removeAttribute("selected");
    });
    county?.parentNode?.appendChild(county);
    setTimeout(() => {
      county?.setAttributeNode(document.createAttribute("selected"));
    }, 0);
    triggerPwsSummariesByCounty({ fips: padZeros(selectedCounty) }).catch(
      () => {
        toastError("Failed to get PWS summaries by county");
      }
    );
  }, [selectedCounty]);

  const mapCallback = useCallback(
    (state: SVGElement): (() => void) => {
      console.count("mapCallback");
      state.style.width = "100%";
      state.style.maxWidth = "400px";
      state.style.height = "fit-content";
      state.style.strokeWidth = "3";
      // add padding in svg viewbox
      setViewbox([
        -50,
        -50,
        parseInt(state.getAttribute("width") ?? "0") + 100,
        parseInt(state.getAttribute("height") ?? "0") + 100,
      ]);

      const counties = state.querySelectorAll("path");
      counties.forEach((county) => {
        const idString = county.id.split("-").pop();
        const id = parseInt(idString ?? "0");
        county.style.stroke = "white";
        const bbox = county.getBBox();
        const centerX = bbox.x + bbox.width / 2;
        const centerY = bbox.y + bbox.height / 2;
        county.style.transformOrigin = `${centerX}px ${centerY}px`;
        county.onclick = () => {
          setSelectedCounty(id);
        };

        // set county opacity
        if (countyMakeUpData && idString) {
          const percent =
            getCountyMakeupPercentages(countyMakeUpData[idString])[viewMapBy] *
              (1 - shadingOffset) +
            shadingOffset;
          const countyShade = calculateOpaqueColor(
            COLOR_MAP.get(viewMapBy) ?? DARK_BLUE,
            percent
          );
          county.style.fill = countyShade;
        }
      });

      const moveFn: (e: MouseEvent) => void = (e) => {
        const pathElement = e.target as SVGPathElement;
        if (
          pathElement?.id !== countyTooltip &&
          pathElement?.tagName === "path"
        ) {
          const rect = pathElement.getBoundingClientRect();
          const newX = rect.x + rect.width / 2;
          const newY = rect.y + rect.height / 2;
          setTooltipPosition(newX, newY);
          setCountyTooltip((e.target as HTMLElement)?.id ?? "");
        }
      };
      const leaveFn: () => void = () => {
        setCountyTooltip(null);
        state.removeEventListener("mousemove", moveFn);
      };

      state.addEventListener("mouseover", moveFn);
      state.addEventListener("mouseleave", leaveFn);
      return () => {
        state.removeEventListener("mouseover", moveFn);
        state.removeEventListener("mouseleave", leaveFn);
      };
    },
    [viewMapBy, countyMakeUpData]
  );

  useEffect(() => {
    if (!countyTooltip) {
      setTooltipContent(null);
      return;
    }
    setTooltipContent(
      <>
        <p style={{ marginBottom: 5 }}>
          <Icon name="map marker alternate" />
          {countyMakeUpData?.[countyTooltip.split("-").pop() ?? ""]
            ?.displayName ?? ""}
        </p>
        <div
          style={{ height: 1, width: "100%", backgroundColor: LIGHTEST_GRAY }}
        ></div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            padding: "10px 10px 0",
            textDecoration: "underline",
          }}
        >
          {countyMakeUpData?.[countyTooltip.split("-").pop() ?? ""]?.pwsNames
            ?.split(", ")
            .map((name) => (
              <span key={name}>{name}</span>
            ))}
        </div>
      </>
    );
  }, [countyTooltip, countyMakeUpData]);

  return (
    <Card
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3>State Overview</h3>
      <div style={{ display: "flex" }}>
        <MapContainer>
          <StateMap
            state={state}
            css={`
              path {
                transition: all 0.5s;
                background-color: #fff;
              }
              path[selected] {
                transform: scale(1.5) translateY(-15px);
                filter: drop-shadow(0px 10px 5px #555555cc);
              }
            `}
            callback={mapCallback}
            viewBox={viewbox.join(" ")}
          />
        </MapContainer>
        <div
          style={{
            width: "50%",
          }}
        >
          <MapDetailsContainer>
            <MapDetail title="View Map By:">
              <Column
                style={{
                  gap: 20,
                  padding: 10,
                }}
              >
                <Column
                  style={{
                    width: "50%",
                    gap: 10,
                  }}
                >
                  <MaterialTypesTitleContainer
                    color={COLOR_MAP.get(viewMapBy) ?? DARK_BLUE}
                  >
                    Material Types
                  </MaterialTypesTitleContainer>
                  <Dropdown
                    placeholder="Select a map"
                    options={(
                      [
                        MaterialType.LEAD,
                        MaterialType.GRR,
                        MaterialType.NONLEAD,
                        MaterialType.UNKNOWN,
                      ] as MaterialType[]
                    ).map((type) => ({
                      text: (
                        <div style={{ display: "flex", gap: 5 }}>
                          <MaterialCircle color={COLOR_MAP.get(type) ?? ""} />
                          <span>{LABEL_MAP.get(type ?? "")}</span>
                        </div>
                      ),
                      value: type,
                    }))}
                    defaultValue={MaterialType.LEAD}
                    onChange={(_, { value }) =>
                      setViewMapBy(value as MaterialType)
                    }
                    selection
                  />
                </Column>
                <div>
                  <Meter
                    color={COLOR_MAP.get(viewMapBy) ?? DARK_BLUE}
                    startColor={calculateOpaqueColor(
                      COLOR_MAP.get(viewMapBy) ?? DARK_BLUE,
                      shadingOffset
                    )}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>0%</span>
                    <span>100%</span>
                  </div>
                </div>
              </Column>
            </MapDetail>
            <MapDetail
              title="Select a county to view"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
              open={!!selectedCounty || undefined}
            >
              <Dropdown
                placeholder="County"
                options={
                  Object.entries(countyMakeUpData ?? {}).map(
                    ([fips, county]) => ({
                      text: county.displayName,
                      value: fips,
                    })
                  ) ?? []
                }
                value={padZeros(selectedCounty) || undefined}
                onChange={(_, { value }) => setSelectedCounty(value as number)}
                style={{
                  width: "fit-content",
                }}
                selection
                search
              />
              <UtilityInfoContainer>
                {!!selectedCounty &&
                  resultPwsSummariesByCounty.currentData &&
                  resultPwsSummariesByCounty.currentData.map((pwsSummary) => {
                    return (
                      <UtilityDetails
                        key={`pws-summary-${pwsSummary.pwsId}`}
                        utility={pwsSummary}
                        style={{
                          maxWidth: 200,
                          minWidth: 150,
                        }}
                      />
                    );
                  })}
              </UtilityInfoContainer>
            </MapDetail>
            <MapDetail
              title={
                <>
                  <Icon name="warning sign" color="yellow" />
                  View unmapped utilities
                </>
              }
            >
              <UtilityInfoContainer>
                {isUnmappedPwsSummariesLoading && <Loader />}
                {!isUnmappedPwsSummariesLoading &&
                isUnmappedPwsSummariesError
                ? (
                  <Error messages={["Error loading unmapped utilities"]} />
                )
                : (
                  unmappedPwsSummariesData?.map((pwsSummary) => {
                    return (
                      <UtilityDetails
                        key={`pws-summary-${pwsSummary.pwsId}`}
                        utility={pwsSummary}
                        style={{
                          maxWidth: 200,
                          minWidth: 150,
                        }}
                      />
                    );
                  })
                )}
              </UtilityInfoContainer>
            </MapDetail>
          </MapDetailsContainer>
        </div>
      </div>
    </Card>
  );
};
