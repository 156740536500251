import { Button, Card, BLACK } from "@120wateraudit/waterworks";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import Page from "src/components/Page";
import { SubmissionPeriodDropdown } from "src/modules/Submissions/SubmissionPeriodDropdown";
import styled from "styled-components";
import PageHeader from "src/components/PageHeader";
import SubmissionsStatusesMetricsCard from "./SubimssionsStatusesMetricsCard";
import { PwsSubmissionPeriodsTable } from "src/modules/Submissions/PwsSubmissionPeriodsTable";
import Loader from "src/components/Loader";
import Exporter from "./Exporter";
import { useConfig, Flags } from "src/hooks/useConfig";
import { useTableState } from "src/hooks/useTableState";

const InventoryPage: React.FC = () => {
  const [submissionPeriodId, setSubmissionPeriodId] = React.useState<number>();
  const [hasStatePortalSidwisExport] = useConfig(Flags.statePortalSidwisExport);
  const [submissionPeriodManagementEnabled] = useConfig(
    Flags.SubmissionPeriodManagement
  );

  const tableContext = useTableState();

  return (
    <Page
      title={
        <TitleContainer>
          <PageHeader>Submissions</PageHeader>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <SubmissionPeriodDropdown
              setSubmissionPeriodId={setSubmissionPeriodId}
            />
            {submissionPeriodManagementEnabled && (
              <CreateNewSubmissionPeriodButton>
                <Link to={`/accountSettings/manageSubmissionPeriods`}>
                  <StyledSpan>
                    Create New Submission Period
                    <FontAwesomeIcon
                      style={{ marginLeft: "5px" }}
                      icon={faArrowUpRightFromSquare}
                    />
                  </StyledSpan>
                </Link>
              </CreateNewSubmissionPeriodButton>
            )}
          </div>
        </TitleContainer>
      }
    >
      {/* eslint-disable-next-line multiline-ternary */}
      {!submissionPeriodId ? (
        <Loader />
      ) : (
        <>
          <TopSectionWrapper>
            <SubmissionsStatusesMetricsCard
              submissionPeriodId={submissionPeriodId}
            />
            <div style={{ marginBottom: "12px" }}>
              {hasStatePortalSidwisExport && (
                <Exporter
                  submissionPeriodId={submissionPeriodId}
                  params={tableContext.params}
                />
              )}
            </div>
          </TopSectionWrapper>
          <Card>
            <PwsSubmissionPeriodsTable
              submissionPeriodId={submissionPeriodId}
              tableContext={tableContext}
            />
          </Card>
        </>
      )}
    </Page>
  );
};

const StyledSpan = styled.span`
  color: ${BLACK};
  font-size: 14px;
  font-weight: 600;
  &:hover {
    opacity: 0.5;
  }
`;

const CreateNewSubmissionPeriodButton = styled(Button)`
  width: fit-content;
  margin-left: auto;
  margin-bottom: -40px;
  font-size: 16px;
  font-weight: 600;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const TopSectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export default InventoryPage;
