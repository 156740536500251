type ENV = 'development' | 'production' | 'staging';

const DEV_SUBDOMAIN = 'state-dashboard-dev';
const STAGING_SUBDOMAIN = 'state-dashboard-staging';
const PROD_SUBDOMAIN = 'state-dashboard';

export const getEnvironment = (): ENV | null => {
  const hostname = window.location.hostname.toLowerCase();
  const isDev = hostname === 'localhost' || hostname.startsWith(DEV_SUBDOMAIN);
  const isStage = hostname.startsWith(STAGING_SUBDOMAIN);
  const isProd = hostname.startsWith(PROD_SUBDOMAIN);

  if (isDev) {
    return 'development';
  }

  if (isStage) {
    return 'staging';
  }

  if (isProd) {
    return 'production';
  }

  return null;
}
