import { useMemo } from "react";

import { Values } from "src/modules/SubmissionPeriods/validation";
import SubmissionPeriod from "src/types/SubmissionPeriod";

export const useInitialValues = (
  submissionPeriod: SubmissionPeriod
): Values => {
  const { name, submissionType, dueDate, lockedDate, overdueSetting, active } =
    submissionPeriod;

  return useMemo(() => {
    const values: Values = {
      name,
      submissionType,
      dueDate,
      lockedDate,
      overdueSetting: overdueSetting ?? "submissionDate",
      active,
    };

    return values;
  }, []);
};
