import React, { useContext, useState, useCallback } from "react";
import styled from "styled-components";
import {
  useAddUpdateTerritoryMutation,
  useGetAllTerritoriesQuery,
} from "src/services/state-submission";
import Territory from "src/types/Territory";
import Loader from "src/components/Loader";
import { ModalContext } from "../Modal";
import { Button, IGNIS_RED } from "@120wateraudit/waterworks";
import { Form as FinalForm, Field } from "react-final-form";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { toastError, toastSuccess } from "src/utils/toast";
import { Form } from "semantic-ui-react";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { TextField } from "src/components/Form";

interface StyledFontAwesomeIconProps extends FontAwesomeIconProps {
  isEdit?: boolean;
}

interface IsEditState {
  [id: number]: boolean;
}

interface StyledDivProps {
  isEdit?: boolean;
}

const ManageTerritories: React.FC = (): JSX.Element => {
  const {
    data: territories = [],
    isFetching,
    refetch,
  } = useGetAllTerritoriesQuery({});

  const { openModal } = useContext(ModalContext);

  const addNewTerritory = async (): Promise<void> => {
    try {
      await openModal("addTerritoryModal", {
        onClose: async () => {
          await refetch();
        },
      });
    } catch (error) {
      toastError("Could not open modal. Please try again.");
      console.error(error);
    }
  };

  const [addTerritory] = useAddUpdateTerritoryMutation();

  const [isEdit, setIsEdit] = useState<IsEditState>({});

  const onSubmit = useCallback(
    async (values: any, form: any) => {
      const editedValues = await isEdit;

      try {
        const territoriesToUpdate = Object.keys(editedValues).filter(
          (key: any) => editedValues[key]
        );

        const extractedValues = Object.keys(values).map((key) => {
          const updateId = key.replace("field", "");
          return {
            updateId,
            name: values[key],
          };
        });

        const matchedValues = extractedValues.filter((item) =>
          territoriesToUpdate.includes(item.updateId.toString())
        );

        if (!matchedValues) {
          return;
        }

        for (const { updateId, name } of matchedValues) {
          await addTerritory({
            updateId,
            name,
          }).unwrap();
        }
        toastSuccess("Your changes have been saved");
        await refetch();
        setIsEdit({});
      } catch (error) {
        if (error !== null && typeof error === "object" && "data" in error) {
          const data = error.data;
          if (
            data !== null &&
            typeof data === "object" &&
            "consumerErrorMessage" in data
          ) {
            const errorMessage = data.consumerErrorMessage as string;
            if (errorMessage.includes("BadRequestException")) {
              toastError(errorMessage.replace("BadRequestException: ", ""));
              form.reset();
              return;
            }
          }
        }

        toastError("Could not create territory. Please try again.");
      }
    },
    [isEdit]
  );

  const deleteTerritoryById = (territoryId: number): void => {
    try {
      openModal("deleteTerritoryModal", {
        onClose: async () => {
          await refetch();
        },
        territoryId,
      });
    } catch (error) {
      toastError("Could not open modal. Please try again.");
      console.error(error);
    }
  };

  return (
    <Wrapper>
      {/* eslint-disable-next-line multiline-ternary */}
      {isFetching ? (
        <LoaderWrapper>
          <Loader size="3x" />
        </LoaderWrapper>
      ) : (
        <FinalForm
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, valid }) => {
            return (
              <div>
                <FormContentContainer
                  onSubmit={() => {
                    void handleSubmit();
                  }}
                >
                  <TopWrapper>
                    <Header>
                      <div>
                        <h3 style={{ fontSize: "24px", fontWeight: "600" }}>
                          Manage Territories
                        </h3>
                        <p style={{ fontSize: "15px", fontWeight: "400" }}>
                          Territories are used to assign and manage your
                          incoming submissions
                        </p>
                      </div>
                    </Header>
                    <Button
                      style={{ margin: "inherit" }}
                      disabled={
                        submitting ||
                        !valid ||
                        !Object.values(isEdit).some((edit) => edit)
                      }
                      variant="primary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </TopWrapper>

                  <FieldsContainer>
                    {territories.map(({ id, name }: Territory) => (
                      <OverFieldContainer key={id}>
                        <FieldContainer isEdit={!isEdit[id]}>
                          <Field<string>
                            className="field"
                            label={`Territory (${id})`}
                            component={TextField}
                            name={"field" + id.toString()}
                            type="text"
                            initialValue={name}
                            placeholder={name}
                          />
                          <StyledFontAwesomeIcon
                            icon={faPenToSquare}
                            onClick={() => {
                              const newIsEditState = {
                                ...isEdit,
                                [id]: !isEdit[id],
                              };
                              setIsEdit(newIsEditState);
                            }}
                            isEdit={isEdit?.[id]}
                          />
                        </FieldContainer>
                        <div>
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            size={"lg"}
                            color={IGNIS_RED}
                            onClick={() => deleteTerritoryById(id)}
                          />
                        </div>
                      </OverFieldContainer>
                    ))}
                  </FieldsContainer>
                </FormContentContainer>
              </div>
            );
          }}
        />
      )}
      <AddNewButton
        onClick={() => {
          void addNewTerritory();
        }}
      >
        + Add New Territory
      </AddNewButton>
    </Wrapper>
  );
};

const FormContentContainer = styled(Form)`
  margin: 1.5rem 1rem;
`;

const Wrapper = styled.div`
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
`;

const StyledFontAwesomeIcon = styled(
  FontAwesomeIcon
)<StyledFontAwesomeIconProps>`
  position: absolute;
  right: 20px;
  top: 65%;
  transform: translateY(-50%);
  cursor: pointer;
  ${({ isEdit }) =>
    isEdit &&
    `
    color: blue;
    transform: translateY(-50%) scale(1.2);
    transition: transform 0.2s ease;
  `}
`;

const OverFieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;

  label {
    font-weight: bold;
  }
`;

const FieldContainer = styled.div<StyledDivProps>`
  position: relative;
  width: 75%;
  div {
    margin: unset;
  }

  ${({ isEdit }) =>
    isEdit &&
    `
   & input {
    pointer-events: none;
  }
  `}
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AddNewButton = styled(Button)`
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
`;
export default ManageTerritories;
