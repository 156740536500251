import { Badge, Button, Card, DARK_BLUE, Detail, ICON_GRAY, WHITE } from "@120wateraudit/waterworks";
import React, { useContext, useCallback, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { LoadingIndicatorPage } from "src/components/LoadingIndicatorPage";
import MaterialsCard from "src/components/MaterialsCard";
import NotFoundCard from "src/components/NotFoundCard";
import Page from "src/components/Page";
import Row from "src/components/Row";
import InventoryTable from "src/modules/Inventory/Table";
import SubmissionDetailsCard from "src/modules/Submissions/DetailsCard";
import { SubmissionChangeDropdown } from "src/modules/Submissions/StatusChangeDropdown";
import {
  useAssignSubmissionAssigneesMutation,
  useUnassignSubmissionAssigneesMutation,
  useGetAccountUsersQuery,
  useGetCurrentPrimacyAgencyQuery,
  useGetSubmissionQuery,
} from "src/services";
import { ModalContext } from "src/modules/Modal";
import { formatDate } from "src/utils/format";
import { StateAccordion } from "src/modules/AdditionalInfo/StateAccordion";
import SubmissionAttachmentAccordion from "src/modules/Attachments/SubmissionAttachmentAccordion";
import PageHeader from "src/components/PageHeader";
import { Dropdown } from "semantic-ui-react";
import { Flags, useConfig } from "src/hooks/useConfig";
import { useTableState } from "src/hooks/useTableState";
import Exporter from "./Exporter";
import { toastError, toastSuccess } from "src/utils/toast";

const SubmissionDetail: React.FC = () => {
  const { closeModal, openModal } = useContext(ModalContext);
  const [statePtdEnabled, statePtdLoaded] = useConfig(Flags.StatePtdSettings);
  const [isLoadingPublishUnpublish, setIsLoadingPublishUnpublish] =
    useState(false);
  const [isLoadingAssignee, setIsLoadingAssignee] =
    useState(false);
  const { id: submissionId } = useParams<{ id: string }>();
  const {
    data: response,
    isError,
    isFetching,
    refetch,
  } = useGetSubmissionQuery(submissionId ?? "");

  const { data: primacyAgency } = useGetCurrentPrimacyAgencyQuery(undefined);
  const { data: accountWriteUsers } = useGetAccountUsersQuery({
    accountId: primacyAgency?.accountId ?? 0,
    permissions: ["stateportal:write"],
  });

  const tableContext = useTableState();

  const submission = response?.data;

  const {
    additionalInfo,
    system,
    totalServiceLinesGalvanized = 0,
    totalServiceLinesLead = 0,
    totalServiceLinesNonLead = 0,
    totalServiceLinesUnknown = 0,
    totalServiceLinesSubmitted = 0,
    assigneeUserId
  } = submission ?? {};
  const { pwsId } = system ?? {};

  const showActivityComments = useCallback(
    () =>
      openModal("activityModal", {
        modalSize: "large",
        submission,
        onClose: async () => {
          closeModal();
        },
      }),
    [openModal, submission]
  );

  const openPublishToPtd = useCallback(
    () =>
      openModal("publishToPtdModal", {
        closeModal: async () => {
          closeModal();
          await refetch();
        },
        selected: [submission],
      }),
    [openModal, closeModal, submission]
  );

  const openUnpublishFromPtd = useCallback(
    () =>
      openModal("unpublishPtdModal", {
        closeModal: async () => {
          closeModal();
          await refetch();
        },
        selected: [submission],
      }),
    [openModal, closeModal, submission]
  );

  const [assignSubmissionUser] = useAssignSubmissionAssigneesMutation();
  const [unassignUsers] = useUnassignSubmissionAssigneesMutation();

  const handleAssignReviewer = useCallback(async (submissionId: number, userId: number | null): Promise<void> => {
    try {
      if (userId === null) {
        await unassignUsers([submissionId]).unwrap();
        toastSuccess("Reviewer unassigned successfully");
        return;
      }
      await assignSubmissionUser({
        assignUsersDtos: [
          {
            submissionId,
            userId,
          },
        ],
      }).unwrap();
      toastSuccess("Reviewer assigned successfully");
    } catch (error) {
      toastError("Failed to assign reviewer");
    }
  }, [assignSubmissionUser, unassignUsers]);

  const assigneeText = useMemo(() => {
    const selectedUser = accountWriteUsers?.items?.find(reviewer => reviewer.id === assigneeUserId);
    const dropdownText = selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : "Unassigned";
    return dropdownText;
  }, [accountWriteUsers, assigneeUserId]);

  const assigneeOptions = useMemo(() => {
    return accountWriteUsers?.items
      ?.map((reviewer) => ({
        value: reviewer.id.toString(),
        text: `${reviewer.lastName}, ${reviewer.firstName}`,
      }))
      .sort((a, b) =>
        a.text.localeCompare(b.text)
      )
      .concat({
        value: "null",
        text: "Unassigned",
      });
  }, [accountWriteUsers]);

  if (isFetching) {
    return <LoadingIndicatorPage />;
  }

  if (!submission || isError) {
    // TODO add back button
    return (
      <Page>
        <NotFoundCard>
          <p>
            We could not find the Submission you were looking for. It may have
            been moved or marked as inactive.
          </p>
          <p>
            We recommend returning to{" "}
            <Link to="/submissions">
              the list of Inventories for your account.
            </Link>
          </p>
        </NotFoundCard>
      </Page>
    );
  }

  return (
    <Page
      fallbackRoute="/submissions"
      hasBackButton
      title={
        <>
          <PageHeader>
            <Link to={`/utilities/${submission.pwsId}`}>{system?.name}</Link>
          </PageHeader>
          <SubmissionChangeDropdown submission={submission} />
          <Button
            style={{
              marginLeft: "10px",
              height: "33px",
              color: WHITE,
              backgroundColor: assigneeUserId ? DARK_BLUE : ICON_GRAY,
            }}
            onClick={() => {
              document.getElementById("assign-review-dropdown")?.click();
            }}
          >
            <Dropdown
              id="assign-review-dropdown"
              onChange={(event, { value }) => {
                if (event.type !== "click") return;
                (async () => {
                  setIsLoadingAssignee(true);
                  if (value === "null") {
                    await handleAssignReviewer(submission.id, null);
                  } else if (!isNaN(parseInt(value as string))) {
                    await handleAssignReviewer(
                      submission.id,
                      parseInt(value as string)
                    );
                  } else {
                    toastError("Invalid value");
                  }
                  setIsLoadingAssignee(false);
                })();
              }}
              options={assigneeOptions}
              value={assigneeUserId ?? ""}
              text={assigneeText}
              disabled={isLoadingAssignee}
              scrolling
            />
          </Button>
          {submission.overdue && (
            <Badge
              style={{
                marginLeft: "10px",
                height: "33px",
                minWidth: "120px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                color: "#fff",
                backgroundColor: "#F30000",
                fontWeight: 600,
              }}
            >
              Overdue
            </Badge>
          )}
          <Button
            onClick={showActivityComments}
            style={{ marginLeft: "10px", height: "33px" }}
            variant="primary"
          >
            Show Activity
          </Button>
          {statePtdEnabled &&
            statePtdLoaded &&
            submission.status === "approved" && (
              <Button
                style={{ marginLeft: "10px", height: "33px" }}
                variant={submission.ptdPublished ? "primary" : "secondary"}
                onClick={() => {
                  document.getElementById("ptd-published-dropdown")?.click();
                }}
              >
                <Dropdown
                  id="ptd-published-dropdown"
                  onChange={(event, { value }) => {
                    if (event.type !== "click") return;
                    setIsLoadingPublishUnpublish(true);
                    if (value === "publish") {
                      openPublishToPtd();
                    } else if (value === "unpublish") {
                      openUnpublishFromPtd();
                    }
                    setIsLoadingPublishUnpublish(false);
                  }}
                  options={[
                    {
                      value: "publish",
                      text: "Publish",
                    },
                    {
                      value: "unpublish",
                      text: "Unpublish",
                    },
                  ]}
                  value={
                    submission.ptdPublished ? "published" : "not-published"
                  }
                  text={submission.ptdPublished ? "Published" : "Not Published"}
                  disabled={isLoadingPublishUnpublish}
                />
              </Button>
            )}
          {submissionId && (
            <Exporter
              submissionId={submissionId}
              params={tableContext.params}
            />
          )}
        </>
      }
    >
      <Content>
        <div
          style={{
            flex: 3,
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <SubmissionDetailsCard submission={submission} />
          <SubmissionInformationCard>
            <div style={{ marginBottom: "0.5rem" }}>
              <h5>Submission Information</h5>
            </div>
            <StyledDetail label="Submission Period:">
              {submission.submissionPeriod.name ?? "--"}
            </StyledDetail>
            <StyledDetail label="Submitted On:">
              {formatDate(submission.submittedOn) ?? "--"}
            </StyledDetail>
            <StyledDetail label="Submitted By:">
              {submission.submittedByName ?? "--"}
            </StyledDetail>
          </SubmissionInformationCard>
          <MaterialWrapper>
            <MaterialsCard
              totalLines={totalServiceLinesSubmitted}
              galvanized={totalServiceLinesGalvanized}
              lead={totalServiceLinesLead}
              nonLead={totalServiceLinesNonLead}
              style={{ flex: 1.5 }}
              unknown={totalServiceLinesUnknown}
            />
          </MaterialWrapper>
        </div>
      </Content>
      <Card style={{ marginTop: "1rem" }}>
        {additionalInfo && (
          <StateAccordion additionalInfo={additionalInfo} pwsId={pwsId} />
        )}
      </Card>
      <Card style={{ marginTop: "1rem" }}>
        {submission?.id && (
          <SubmissionAttachmentAccordion submissionId={submission?.id} />
        )}
      </Card>
      <Card style={{ marginTop: "1rem" }}>
        <InventoryTable
          submissionId={submissionId}
          tableContext={tableContext}
        />
      </Card>
    </Page>
  );
};

const Content = styled(Row)`
  gap: 32px;
  align-items: start;
  flex-wrap: wrap-reverse;
`;

const StyledDetail = styled(Detail)`
  display: flex;
  label {
    flex: 0.6;
  }

  span {
    flex: 1;
  }
  text-align: left;
  margin-bottom: 0.5rem;
`;

const SubmissionInformationCard = styled(Card)`
  flex: 0.5;

  @media (max-width: 380px) {
    flex: 1;
  }
`;

const MaterialWrapper = styled.div`
  display: flex;
  flex: 2;
  gap: 1rem;
  flex-direction: column;

  @media (max-width: 700px) {
    flex: auto;
  }
`;

export default SubmissionDetail
