import { Input, IGNIS_RED } from "@120wateraudit/waterworks";
import styled from "styled-components";

export const FieldInput = styled(Input)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;

  input {
    border-width: 2px;
    border-color: ${(props: { error: any }) =>
      props.error ? IGNIS_RED : "rgba(34, 36, 38, .15)"} !important;
  }

  label {
    margin-right: auto;
  }
`;
