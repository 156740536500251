import noop from "lodash/noop";
import React, { FC } from "react";
import styled from "styled-components";
import {
  Button,
  GRAY,
  IGNIS_RED,
  TERRA_GREEN,
} from "@120wateraudit/waterworks";
import { Form as FinalForm, Field, FieldRenderProps } from "react-final-form";
import { Form, Checkbox, Popup } from "semantic-ui-react";
import { RiInformation2Line } from "react-icons/ri";

import TextField from "src/components/Form/TextField";
import { RadioCard } from "../../Ptd/RadioCard";
import { DatePicker } from "src/components/Form";
import { useInitialValues } from "./hooks";
import Dropdown from "src/components/Form/Dropdown";

interface Props {
  refetch: any;
  toggleCreateMode: any;
}

const CreateSubmissionPeriod: FC<Props> = ({
  refetch = noop,
  toggleCreateMode = noop,
}) => {
  const initialValues = useInitialValues();

  const onSubmit = async (): Promise<void> => {
    console.log("You created your Submission Period!"); //eslint-disable-line
  };

  return (
    <Wrapper>
      <FinalForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, valid }) => (
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <Form onSubmit={handleSubmit}>
            <div>
              <TopWrapper>
                <Header>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <h3 style={{ fontSize: "24px", fontWeight: "600" }}>
                      New Submission Period
                    </h3>
                    <div>
                      <AddNewButton
                        variant="primary"
                        style={{ marginRight: "10px" }}
                        onClick={toggleCreateMode}
                      >
                        Save
                      </AddNewButton>
                      <AddNewButton
                        style={{ backgroundColor: GRAY }}
                        onClick={toggleCreateMode}
                      >
                        Cancel
                      </AddNewButton>
                    </div>
                  </div>
                </Header>
              </TopWrapper>
              <SubmissionPeriodsContainer>
                <div>
                  <Title>Submission Name</Title>
                  <Field<string>
                    wrapperStyling={{ margin: "0px" }}
                    component={TextField}
                    name="name"
                    placeholder="Enter name for submission period"
                  />
                </div>
                <div>
                  <Title>Submission Type</Title>
                  <Field<string>
                    wrapperStyling={{ margin: "0px" }}
                    component={Dropdown}
                    name="submissionType"
                    options={[
                      {
                        text: "Inventory",
                      },
                    ]}
                    placeholder="Select Submission Type"
                  />
                </div>

                <div style={{ display: "flex", width: "75%", gap: "16px" }}>
                  <div style={{ width: "50%" }}>
                    <Title>
                      Due Date{" "}
                      <Popup
                        trigger={
                          <RiInformation2Line
                            size="20px"
                            style={{ marginLeft: "7px" }}
                          />
                        }
                        content="The date used when calculating if Submissions are Overdue."
                        basic
                        position="bottom center"
                      />
                    </Title>
                    <Field
                      wrapperStyling={{ margin: "0px" }}
                      component={DatePicker}
                      name="dueDate"
                      placeholder="Select Due Date"
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Title>
                      Close Date (Optional){" "}
                      <Popup
                        trigger={
                          <RiInformation2Line
                            size="20px"
                            style={{ marginLeft: "7px" }}
                          />
                        }
                        content="The date that determines the end of the Submission Period."
                        basic
                        position="bottom center"
                      />
                    </Title>
                    <Field
                      wrapperStyling={{ margin: "0px" }}
                      component={DatePicker}
                      name="lockedDate"
                      placeholder="Select Close Date"
                    />
                  </div>
                </div>
                <StyledFieldContainer>
                  <Title>
                    Overdue Status{" "}
                    <Popup
                      trigger={
                        <RiInformation2Line
                          size="20px"
                          style={{ marginLeft: "7px" }}
                        />
                      }
                      content="The following field is used to determine how Overdue status will be calculated for submissions in this period."
                      basic
                      position="bottom center"
                    />
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "24px",
                    }}
                  >
                    <Field<boolean> name="overdueSetting" initialValue={true}>
                      {({ input }: FieldRenderProps<boolean, HTMLElement>) => (
                        <>
                          <RadioCard
                            title={"First Submission Date"}
                            description={
                              "Overdue status will be based on the date of the first submission in this period. "
                            }
                            selected={input.value}
                            onClick={() => {
                              input.onChange(!input.value);
                            }}
                          />
                          <RadioCard
                            title={"Submission Date"}
                            description={
                              "Overdue status will be based on the date of the most recent submission in this period."
                            }
                            selected={!input.value}
                            onClick={() => {
                              input.onChange(!input.value);
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </StyledFieldContainer>
                <StyledFieldContainer>
                  <Title>
                    Submission Period Status{" "}
                    <Popup
                      trigger={
                        <RiInformation2Line
                          size="20px"
                          style={{ marginLeft: "7px" }}
                        />
                      }
                      content="This toggle is used to determine whether or not submissions can be received."
                      basic
                      position="bottom center"
                    />
                  </Title>
                  <Field<boolean> name="active" initialValue={true}>
                    {({ input }: FieldRenderProps<boolean>) => {
                      const val = input.value ? "true" : "false";
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Checkbox
                            defaultChecked={input.value}
                            value={val}
                            onChange={() => {
                              input.onChange(!input.value);
                            }}
                            toggle
                          />
                          <Text
                            style={{
                              color: val ? TERRA_GREEN : IGNIS_RED,
                              fontSize: "15px",
                            }}
                          >
                            {val ? "Open" : "Closed"}
                          </Text>
                        </div>
                      );
                    }}
                  </Field>
                </StyledFieldContainer>
              </SubmissionPeriodsContainer>
            </div>
          </Form>
        )}
      />
    </Wrapper>
  );
};

const Text = styled.span`
  font-weight: 400;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.6px;
`;

const StyledFieldContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  max-width: 815px;
  ${({ disabled }) =>
    disabled &&
    `> *:not(.no-disable) {
      opacity: 0.25;
      pointer-events: none;
    }`}
`;

const Wrapper = styled.div`
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;
const SubmissionPeriodsContainer = styled.div`
  display: grid;
  gap: 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

const AddNewButton = styled(Button)`
  &:hover {
    opacity: 0.5;
  }
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: white;
`;

export default CreateSubmissionPeriod;
